// 2022-02-24 인수인계
// 기능 : 모바일에서만 보이는 일정 전체보기 페이지입니다. (/cal > 좌측 전체보기)

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import "../assets/css/cal.css";
import {
  getParameterByName,
  toggleClass,
  IsLogin,
  noData,
  getToday,
  numberWithCommas,
  inputChanger,
  filteringBtns,
} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import MainMenu from "../components/main_menu";
import * as FullCalendar from "@fullcalendar/core";
import { updateScheduleData } from "../shared/chat";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import AddMember from "../components/addMember";
import TeamworkModule from "../components/teamworkModules";
const CalAll = () => {
  const navigate = useNavigate();
  const ref = useRef(null);

  const [month, setMonth] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedMonth = `${today.getFullYear()}년 ${String(
        today.getMonth() + 1
    ).padStart(2, "0")}월`;
    setMonth(formattedMonth);
  }, []);

  const plugins = [dayGridPlugin, listPlugin, interactionPlugin];

  function getCalList(date) {
    document
      .querySelectorAll("div.data_wrapper > ul.data_list > li")
      .forEach((item, idx) => {
        item.remove();
      });

    excute(document.querySelectorAll(".data_list")[0]);
    excute(document.querySelectorAll(".data_list_target")[0]);

    function excute(appendTarget) {
      var newDate = date.indexOf("-") !== -1 ? date.replace(/-/g, "/") : date;

      var nowDate = new Date(newDate),
        yeDay = nowDate.getDate() - 1,
        yeDate = new Date(nowDate.setDate(yeDay));

      var yeY = yeDate.getFullYear(),
        yeM = yeDate.getMonth() + 1,
        yeD = yeDate.getDate();

      if (yeM < 10) {
        yeM = "0" + yeM;
      }
      if (yeD < 10) {
        yeD = "0" + yeD;
      }
      var yeDateForm = yeY + "-" + yeM + "-" + yeD;
      fetch(
        `https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=my&month=${yeY + "-" + yeM}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            document.querySelectorAll(".scheduleTotalCount")[0].textContent = data?.data?.total_count;
            document.querySelectorAll(".scheduleNonCompleteCount")[0].textContent = data?.data?.non_complete_count;
            document.querySelectorAll(".scheduleCompleteCount")[0].textContent = data?.data?.complete_count;
            if (data.data.schedules.length !== 0) {
              noData(false);
              data.data.schedules.forEach((item, idx) => {
                var begin_dateItem =
                    item.begin_date.indexOf("-") !== -1
                      ? new Date(item.begin_date.replace(/-/g, "/"))
                      : new Date(item.begin_date),
                  begin_dateItemChk =
                    begin_dateItem.getFullYear() +
                    "-" +
                    begin_dateItem.getMonth() +
                    "-" +
                    begin_dateItem.getDate(),
                  now_dateItemChk =
                    new Date(newDate).getFullYear() +
                    "-" +
                    new Date(newDate).getMonth() +
                    "-" +
                    new Date(newDate).getDate();
                if (begin_dateItemChk == now_dateItemChk) {
                  //요청 일정 미노출 처리
                  if (item.status !== 0) {
                    let className, title;
                    if (item.status == 1) {
                      (className = "type_1"),
                        (title = `요청`);
                    } else if (item.status == 2) {
                      (className = "type_3"),
                        (title = `완료`);
                    } else if (item.status == 5) {
                      (className = "type_4"),
                        (title = `보류`);
                    } else if (item.status == 10) {
                      (className = "type_5"),
                        (title = `취소`);
                    } else if (item.status == 0) {
                      // (className = "type_1"), (title = "요청");
                    }
                    const htmlString = `<li class="new_cal_item active" status="${
                      item.status
                    }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
                      item.contents && item.contents
                    }</span> <h1> <span class="date_created_at">${
                      item.begin_date
                    }</span> <span class="data_price_detail">${numberWithCommas(
                      item.price
                    )}원</span> </h1> </div> </li>`;
                    if (item.status !== 0) {
                      appendTarget.insertAdjacentHTML("beforeend", htmlString);

                      document
                        .querySelectorAll(".new_cal_item")[0]
                        .addEventListener("click", function () {
                          // navigate(`/date_detail?idx=${item.id}`);
                          updateScheduleData(item.id);
                          _animateRightModule(
                            true,
                            document.querySelectorAll(".d_4")[0]
                          );
                          document
                            .querySelectorAll(".common_more_wrapper li")
                            .forEach((item2, idx2) => {
                              item2.setAttribute("data-schedule_id", item.id);
                            });
                        });
                      document
                        .querySelectorAll(".new_cal_item")[0]
                        .classList.remove("new_cal_item");
                    }
                  }
                }
              });
            } else {
              noData(true);
            }

            if (data.data.schedules.next_page_url) {
              excute(data.data.schedules.next_page_url + pram);
            } else {
              if (
                !document.querySelectorAll(
                  "div.data_wrapper > ul.data_list > li.active"
                )[0]
              ) {
                noData(true);
              }
            }
          } else {
            createPopup({
              type: "confirm",
              title: "조회 실패",
              content: data.alert,
            });
          }
        });
    }
  }

  function initCalendar(target) {
    fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=my`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myCalData) {
        const scheduleData = myCalData.data.schedules;
        const events = scheduleData.map((item, idx) => {
          let className;
          if (item.status == 1) {
            className = "status_1 cal_status";
          } else if (item.status == 2) {
            className = "status_3 cal_status";
          } else if (item.status == 5) {
            className = "status_4 cal_status";
          } else if (item.status == 10) {
            className = "status_5 cal_status";
          } else {
            return;
          }

          return {
            id: idx + 1,
            start: item.begin_date,
            className: className,
          };
        });
        let option = {
          plugins: plugins,
          initialView: "dayGridMonth",
          dayMaxEvents: 5,
          events: events,
          headerToolbar: {
            start: "",
            center: "",
            end: "prev,next today",
          },
          moreLinkContent: function (args) {
            return "+" + args.num;
          },
          datesSet: function (info) {
            setMonth(info.view.title)
          },
          dateClick: function (info) {
            if (document.querySelectorAll(".fc_actived_day")[0]) {
              document
                .querySelectorAll(".fc_actived_day")
                .forEach((item, idx) => {
                  item.classList.remove("fc_actived_day");
                });
            }
            info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
            document.querySelectorAll("div.cal_guide h1")[0].textContent =
              info.dateStr;
            getCalList(info.dateStr);
          },
        };

        const calendar = new FullCalendar.Calendar(target, option);
        calendar.setOption("locale", "ko");

        calendar.render();
        const eventLength = scheduleData.length;

		 function resetOption() {
		   calendar.addEventSource({ ...option, events: events });

		   const eventLength = scheduleData.length;
		   for (let index = 0; index < eventLength + 1; index++) {
			 calendar.getEventById(index)?.remove();
		   }
		 }
		 resetOption();

		 document
		   .querySelector(".fc-next-button")
		   .addEventListener("click", (e) => {
			 resetOption();
		   });

		 document
		   .querySelector(".fc-prev-button")
		   .addEventListener("click", (e) => {
			 resetOption();
		   });
      });
  }

  useEffect(() => {
    inputChanger();
    let uploadedfileArr;
    let calendar;
    init();

    getCalList(getToday());
    //내 일정 리스트 가져오기

    getMyDate();
    function getMyDate() {
      fetch(`https://api.olapse.fifteenh.io/api/mypage`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {});
    }

    //파입 업로드 기능
    function init() {
      document.querySelectorAll("div.cal_guide h1")[0].textContent = getToday();
    }

    document
      .querySelectorAll(".cal_all_back")[0]
      .addEventListener("click", function () {
        if (document.querySelectorAll(".fc_actived_day")[0]) {
          const activeDate = document
            .querySelectorAll(".fc_actived_day")[0]
            .parentNode.parentNode.parentNode.getAttribute("data-date");
          window.location.href = `/cal?date=${activeDate}`;
        } else {
          navigate(-1);
        }
      });
    // filteringBtns("div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li", "active");
    filteringBtns(
      "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li",
      "active"
    );
    //일정 완료처리
    let paymentType1Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_1 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType1Chk) {
          paymentType1Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/complete`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType1Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "일정완료",
                    content: "일정을 완료하였습니다.",
                    custom: () => {
                      // location.reload(true);
                      var pel = document.querySelectorAll(
                        ".payment_select_popup_wrapper_1"
                      )[0];
                      _animationDisappearBottomUp(
                        pel.querySelectorAll(".payment_select_popup_box")[0]
                      );
                      _animateRightModule(
                        false,
                        document.querySelectorAll(".d_4")[0]
                      );
                      setTimeout(() => {
                        pel.style.display = "none";
                        location.reload(true);
                      }, 400);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "일정완료 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType1Chk = true;
            createPopup({
              type: "reTry",
              title: "일정 완료 불가",
              content: "일정을 하나 이상 선택해주세요.",
            });
          }
        }
      });

    //일정 완료처리
    let paymentType2Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_2 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType2Chk) {
          paymentType2Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/type/modify`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType2Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "변경완료",
                    content: "결제타입을 변경하였습니다.",
                    custom: () => {
                      location.reload(true);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "타입변경 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType2Chk = true;
            createPopup({
              type: "reTry",
              title: "타입 변경 불가",
              content: "타입을 하나 이상 선택해주세요.",
            });
          }
        }
      });
    document.querySelectorAll(".toggle_button").forEach((item, idx) => {
      toggleClass(item, item, "active");
    });
    //일정 완료 취소(단순 팝업 닫기)
    document
      .querySelectorAll(
        ".payment_select_popup_box_button_wrapper button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        _animationDisappearBottomUp(
          document.querySelectorAll(
            "div.payment_select_popup_wrapper div.payment_select_popup_box"
          )[0]
        );
        setTimeout(() => {
          document.querySelectorAll(
            ".payment_select_popup_wrapper"
          )[0].style.display = "none";
        }, 400);
      });
  }, []);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      initCalendar(target);
    }
  }, [ref]);

  return (
    <>
      <div className="wrap">
        <MainMenu />
        <AddMember />
        <div className="chat_wrapper">
          <div style={{ flexDirection: "column" }} className="chat_header">
            <div className="chat_header_title_row">
              <h1>내 일정</h1>
              <button
                onClick={() =>
                  _animateRightModule(
                    true,
                    document.querySelectorAll(".d_8")[0]
                  )
                }
                className="add_cal"
              />
            </div>
            <div className="chat_header_title_row_2">
              <h1>다가올 일정</h1>
              <button
                onClick={() => navigate("/member_date?idx=my")}
                className="next_sch"
              >
                전체보기
              </button>
            </div>
          </div>
          <div className="data_wrapper data_wrapper_m">
            {/* 일정 리스트 */}
            <ul className="data_list" />
          </div>
        </div>
        <div
            style={{display: "flex", zIndex: "11", paddingBottom: "4rem"}}
            className="pc_right_area cal_all"
        >
          <div className="pc_right_area_top">
            <div className="left cal_all_back">
              <img src="/assets/images/icon_back.svg" alt=""/>
            </div>
            <h1 className="right_area_title">내 일정</h1>
          </div>
          <div className="more_area">
            <div className="month">
              {month?.split(' ')[0]} <span>{month?.split(' ')[1]}</span>
            </div>
            <ul className="more_cal_list">
              <li onClick={() => (window.location.href = "/more/schedule_list?isReq=0")}>
                <h1 className="scheduleTotalCount">-</h1>
                <span>전체 일정</span>
              </li>
              <li onClick={() => (window.location.href = "/more/schedule_list?isReq=0")}>
                <h1 className="scheduleNonCompleteCount">-</h1>
                <span>미처리 일정</span>
              </li>
              <li onClick={() => (window.location.href = "/more/schedule_list?isReq=1")}>
                <h1 className="scheduleCompleteCount">-</h1>
                <span>완료 일정</span>
              </li>
            </ul>
          </div>
          <div ref={ref} className="calendar"/>

          <div className="cal_guide">
            <ul>
              <li>
                <p/>
                <span>요청</span>
              </li>
              <li style={{display: "none"}}>
                <p/>
                <span>수락</span>
              </li>
              <li>
                <p/>
                <span>완료</span>
              </li>
              <li>
                <p/>
                <span>보류</span>
              </li>
              <li>
                <p/>
                <span>취소</span>
              </li>
            </ul>
            <h1/>
          </div>
          <div className="data_wrapper">
            {/* 일정 리스트 */}
            <ul className="data_list data_list_target"/>
            <div className="no_data">
              <span>일정이 없습니다.</span>
            </div>
          </div>
        </div>
      </div>
      <TeamworkModule/>
    </>
  );
};

export default CalAll;
