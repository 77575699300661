// 2022-02-24 인수인계
// 기능 : 로그인페이지입니다.

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import createPopup from "../components/createPopup.js";
import { getParameterByName } from "../shared/script";

const Login = () => {
  const navigate = useNavigate();

  const login = () => {
    if (
      String(document.querySelector("#id").value).length !== 0 &&
      String(document.querySelector("#password").value).length !== 0
    ) {
      let formdata = new FormData();
      formdata.append("login_id", document.querySelector("#id").value);
      formdata.append("password", document.querySelector("#password").value);
      fetch("https://api.olapse.fifteenh.io/api/login", {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            fetch(`https://api.olapse.fifteenh.io/api/access/result`, {
              method: "GET",
              headers: {
                Authorization: "Bearer " + data.data.token.access_token,
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data2) {
                if (data2.data.access_limit_reason == null) {
                  localStorage.setItem("token", data.data.token.access_token);
                  localStorage.setItem(
                    "refresh_token",
                    data.data.token.refresh_token
                  );
                  setTimeout(() => {
                    if (getParameterByName("redirectUrl")) {
                      location.href = getParameterByName("redirectUrl");
                    } else {
                      navigate("/cal");
                    }
                  }, 100);
                } else {
                  createPopup({
                    type: "custom_select_2",
                    title: "이용제한",
                    content: `<span>Olapse 운영정책에 따라 회원의 신고가<br>접수되어 다음과 같이 접속제한 되었습니다.</span><br><span>제한 일시 : ${data2.data.access_limit_begin_date}<br>해제 일시 : ${data2.data.access_limit_date}<br> 제한 사유 : ${data2.data.access_limit_reason}</span><br><span>문의사항은 고객센터 <span class="blue">1661-9574</span>로 문의주시기 바랍니다.</span>`,
                    firstButtonClickFunction: () => {},
                    secondButtonClickFunction: () => {},
                    button_text_1: "",
                    button_text_2: "확인",
                  });
                }
              });
          } else {
            createPopup({
              type: "reTry",
              title: "로그인 실패",
              content: "아이디 또는 비밀번호가 <br /> 올바르지 않습니다.",
            });
          }
        });
    } else {
      createPopup({
        type: "reTry",
        title: "로그인 실패",
        content: "아이디와 비밀번호를 모두 입력해주세요.",
      });
    }
  };

  useEffect(() => {
    document
      .querySelectorAll(".login_input_wrapper input")
      .forEach((item, idx) => {
        item.addEventListener("input", function () {
          if (
            String(
              document.querySelectorAll(".login_input_wrapper input")[0].value
            ).length > 0 &&
            String(
              document.querySelectorAll(".login_input_wrapper input")[1].value
            ).length > 0
          ) {
            document
              .querySelectorAll("div.login_wrapper button")[0]
              .classList.add("active");
          } else {
            document
              .querySelectorAll("div.login_wrapper button")[0]
              .classList.remove("active");
          }
        });
      });
  }, []);

  return (
    <>
      <div id="wrap">
        <div className="login_wrapper">
          <img src="/assets/images/logo.svg" alt="logo" />
          <div className="login_input_wrapper">
            <input id="id" placeholder="아이디" type="id" />
            <input id="password" placeholder="비밀번호" type="password" />
          </div>
          <button onClick={login}>로그인</button>
          <ul>
            <li>
              <a onClick={() => navigate("/find_id_1")}>아이디찾기</a>
            </li>
            <li>
              <a onClick={() => navigate("/find_pass_1")}>비밀번호찾기</a>
            </li>
            <li>
              <a onClick={() => navigate("/agree")}>회원가입</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Login;
