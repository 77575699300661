// 2022-02-24 인수인계
// 기능 : 기본 채팅창 우측 UI (오랩스 로고)

const Basic = () => {
    return (
        <>
        </>
    );
};
export default Basic;
