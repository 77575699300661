// 2022-02-24 인수인계
// 기능 : 일정 선택 팝업입니다. 팝업과 연계되는 기능도 일부 작성되어있습니다.

import React, { useEffect } from "react";
import "../assets/css/main_2.css";
import { useNavigate } from "react-router-dom";
import {
  getParameterByName,
  getToday,
  lastWeek,
  noData,
  numberWithCommas,
  imgViewerFunction,
  imgViewerFunctionSetting,
  filteringBtns,
} from "../shared/script.js";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import createPopup from "./createPopup";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { updateScheduleData } from "../shared/chat.js";
import { shareExcute } from "../shared/schedule";

const DatePicker = () => {
  const navigate = useNavigate();
  const imgTypeArr = ["jpg", "jpeg", "gif", "bmp", "png"];
  const videoArr = [
    "mp4",
    "m4v",
    "avi",
    "asf",
    "wmv",
    "mkv",
    "ts",
    "mpg",
    "mpeg",
    "mov",
    "flv",
    "ogv",
  ];

  useEffect(() => {
    const confirmdElement = document.querySelectorAll(
      "div.data_wrapper > div.data_detail_select > div.select_right > a"
    )[0];
    let isSubs;
    fetch(`https://api.olapse.fifteenh.io/api/data/store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.user_data_store == null) {
          isSubs = false;
        } else {
          isSubs = true;
        }
      });

    let url;
    //풀캘린더 세팅
    window.FullCalendar = Calendar;
    window.dayGridPlugin = dayGridPlugin;
    window.listPlugin = listPlugin;
    window.interactionPlugin = interactionPlugin;
    <script type="text/javascript" src="../assets/js/main.js"></script>;

    let requestData = {
      type: "request",
      start_date: lastWeek(),
      end_date: getToday(),
      sort: "newest",
      keyword: "",
      status: [],
    };
    let myId, myPermissionInfo;

    fetch("https://api.olapse.fifteenh.io/api/member", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        myId = data.data.my_info.id;
      });

    if (getParameterByName("isReq") == "0") {
      url = `https://api.olapse.fifteenh.io/api/schedule/request?`;
    } else {
      url = `https://api.olapse.fifteenh.io/api/schedule/accept?`;
    }

    url = `https://api.olapse.fifteenh.io/api/schedule/accept?`;
    reqDataList();

    //시작
    init();

    //정산
    const paymentShare = (id) => {
      let formdata = new FormData();
      let scheduleIdArr = [];
      scheduleIdArr.push(id);
      formdata.append("schedule_id[]", scheduleIdArr);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/calculate/complete`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "정산 성공",
              content: "정산이 완료되었습니다.",
              custom: () => {
                window.location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "confirm",
              title: "정산 실패",
              content: data.alert,
            });
          }
        });
    };

    //검색 모듈
    function searchModuleFunction() {
      const searchModuleElement = document.querySelectorAll(
        "div.top.search_module"
      )[0];
      //닫기
      document
        .querySelectorAll(".close_search_module")[0]
        .addEventListener("click", function () {
          searchModuleElement.style.display = "none";
        });
      //열기
      document
        .querySelectorAll(".open_search_module")[0]
        .addEventListener("click", function () {
          searchModuleElement.style.display = "flex";
          document
            .querySelectorAll(
              "div.top.search_module div.left div.search_input_wrapper input#search_input"
            )[0]
            .focus();
        });

      document
        .querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper input#search_input"
        )[0]
        .addEventListener("keydown", function (e) {
          if (e.keyCode == 13) {
            requestData.keyword = this.value;
            searchModuleElement.style.display = "none";
            reqDataList();
          }
        });

      document
        .querySelectorAll(".search_submit_button")[0]
        .addEventListener("click", function () {
          requestData.keyword = document.querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0].value;
          searchModuleElement.style.display = "none";
          reqDataList();
        });
      document
        .querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper p.del_text"
        )[0]
        .addEventListener("click", function () {
          document.querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0].value = "";
        });
    }

    //결제
    const payment = (id) => {
      if (
        String(document.querySelector("#payment_name").value).length !== 0 &&
        String(document.querySelector("#payment_card_number").value).length !==
          0 &&
        String(document.querySelector("#payment_due_date").value).length !== 0
      ) {
        let formdata = new FormData();
        formdata.append("schedule_id", id);
        formdata.append("name", document.querySelector("#payment_name").value);
        formdata.append(
          "card_num",
          document.querySelector("#payment_card_number").value
        );
        formdata.append(
          "card_expire",
          document.querySelector("#payment_due_date").value
        );
        formdata.append(
          "card_quota",
          document.querySelectorAll("#payment_month option:checked")[0].value
        );
        fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/payment`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "결제 완료",
                content: "결제가 완료되었습니다.",
                custom: () => {
                  window.location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "결제 실패",
                content: data.alert,
              });
            }
          });
      } else {
        createPopup({
          type: "reTry",
          title: "결제 실패",
          content: "모든 필수 정보를 입력해주세요.",
        });
      }
    };
    //일정 수락
    function acceptSchedule(scheduleId) {
      let formdata = new FormData();
      formdata.append("schedule_id", scheduleId);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/accept`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "일정수락 완료",
              content: "일정을 수락하였습니다.",
              custom: () => {
                if (location.pathname == "/chat") {
                  disableAllPopup();
                } else {
                  window.location.reload(true);
                }
              },
            });
          } else {
            createPopup({
              type: "customs",
              title: "일정수락 실패",
              content: "수락할 수 없는 일정입니다.",
              custom: () => {
                window.location.reload(true);
              },
            });
          }
        });
    }
    //엑셀 다운로더 세팅
    function setDownloader() {
      if (getParameterByName("isReq") == "1") {
        //받은일정
        let status = "";
        if (requestData.status.length !== 0) {
          requestData.status.forEach((item, idx) => {
            status += `&status[]=${item}`;
          });
        }
        return `https://api.olapse.fifteenh.io/api/schedule/accept/excel?begin_date=${
          requestData.start_date
        }&end_date=${requestData.end_date}&order=${requestData.sort}&search=${
          requestData.keyword
        }&access_token=${localStorage.getItem("token")}${status}`;
      } else {
        //보낸일정
        let status = "";
        if (requestData.status.length !== 0) {
          requestData.status.forEach((item, idx) => {
            status += `&status[]=${item}`;
          });
        }

        return `https://api.olapse.fifteenh.io/api/schedule/request/excel?begin_date=${
          requestData.start_date
        }&end_date=${requestData.end_date}&order=${requestData.sort}&search=${
          requestData.keyword
        }&access_token=${localStorage.getItem("token")}${status}`;
      }
    }

    //일정 데이터 가져옴
    function reqDataList() {
      const appendTarget = document.querySelectorAll(".data_list")[0];
      let status = "";
      if (requestData.status.length !== 0) {
        requestData.status.forEach((item, idx) => {
          status += `&status[]=${item}`;
        });
      }
      let pram = `&order=${requestData.sort}&begin_date=${requestData.start_date}&end_date=${requestData.end_date}&search=${requestData.keyword}${status}`;
      appendTarget.querySelectorAll("li").forEach((item, idx) => {
        item.remove();
      });
      inner(url);
      let totalPrice = 0,
        nowPage = 1;
      function inner(url) {
        document.querySelectorAll(".chat_loadingSub")[0].style.display = "flex";
        fetch(`${url}${pram}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.data.schedules.current_page == 1) {
              totalPrice = 0;
              document.querySelectorAll(
                "div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(1)"
              )[0].textContent = `0건`;
              document.querySelectorAll(
                "div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(2)"
              )[0].textContent = `0원`;
              document.querySelectorAll(
                "div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(1)"
              )[0].textContent = `${data.data.all_schedule_count}건`;
              document.querySelectorAll(
                "div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(2)"
              )[0].textContent = `${numberWithCommas(
                data.data.all_schedule_price
              )}원`;
            }
            if (data.success == true) {
              // document.querySelectorAll("div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(1)")[0].textContent = `${data.data.all_schedule_count ? data.data.all_schedule_count : 0}건`;
              // document.querySelectorAll("div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(2)")[0].textContent = `${data.data.all_schedule_price ? numberWithCommas(data.data.all_schedule_price) : 0}원`;
              if (data.data.schedules.data.length !== 0) {
                noData(false);
                data.data.schedules.data.forEach((item, idx) => {
                  totalPrice = totalPrice + item.price;
                  let className, title;
                  if (item.status == 0 || item.status == 1) {
                    className = "type_1";
                    title = "요청";
                  } /*else if (item.status == 1) {
                    className = "type_2";
                    title = `수락`;
                  }*/ else if (item.status == 2) {
                    className = "type_3";
                    title = `완료`;
                  } else if (item.status == 5) {
                    className = "type_4";
                    title = `보류`;
                  } else if (item.status == 10) {
                    className = "type_5";
                    title = `취소 ${item.title}`;
                  }
                  const htmlString = `<li teamworkId="${
                    item.teamwork_id
                  }" class="new_schedule_item active" status="${
                    item.status
                  }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
                    item.contents
                  }</span> <h1> <span class="date_created_at">${
                    item.created_at
                  }</span> <span class="data_price_detail">${numberWithCommas(
                    item.price
                  )}원</span> </h1> </div> </li>`;
                  appendTarget.insertAdjacentHTML("beforeend", htmlString);
                  //상태값별 노출
                  if (requestData.status.length !== 0) {
                    if (
                      requestData.status.indexOf(
                        document
                          .querySelectorAll(".new_schedule_item")[0]
                          .getAttribute("status")
                      ) == -1
                    ) {
                      document
                        .querySelectorAll(".new_schedule_item")[0]
                        .classList.remove("active");
                    }
                  }
                  document
                    .querySelectorAll(".new_schedule_item")[0]
                    .addEventListener("click", function () {
                      _animateRightModule(
                        true,
                        document.querySelectorAll(".d_4")[0]
                      );
                      if (getParameterByName("isReq") == "1") {
                        updateScheduleData(item.id, "팀원");
                      } else {
                        updateScheduleData(item.id, "팀장");
                      }
                      document
                        .querySelectorAll(".common_more_wrapper li")
                        .forEach((item2, idx2) => {
                          item2.setAttribute("data-schedule_id", item.id);
                        });
                      document
                        .querySelectorAll(".edit_schedule")[0]
                        .setAttribute("data-schedule_id", item.id);
                    });

                  myPermission(
                    document.querySelectorAll(".new_schedule_item")[0],
                    item.teamwork_id
                  );
                  document
                    .querySelectorAll(".new_schedule_item")[0]
                    .classList.remove("new_schedule_item");
                  // if(data.data.schedules.current_page == data.data.schedules.last_page && data.data.schedules.data.length - 1 == idx){
                  //     document.querySelectorAll("div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(1)")[0].textContent = `${data.data.schedules.total}건`;
                  //     document.querySelectorAll("div.data_wrapper > div.data_price_row > h1 > span:nth-of-type(2)")[0].textContent = `${numberWithCommas(totalPrice)}원`;
                  // }
                });
              } else {
                noData(true);
              }

              if (data.data.schedules.next_page_url) {
                inner(data.data.schedules.next_page_url + pram);
              } else {
                document.querySelectorAll(".chat_loadingSub")[0].style.display =
                  "none";
                if (
                  !document.querySelectorAll(
                    "div.data_wrapper > ul.data_list > li.active"
                  )[0]
                ) {
                  noData(true);
                }
              }
              if (isSubs == false) {
                document
                  .querySelectorAll(".excel_downloader")[0]
                  .addEventListener("click", function () {
                    createPopup({
                      type: "custom_select_2",
                      title: "엑셀다운로드 안내",
                      content: `<span>엑셀 다운로드를 받으시기 위해서는<br>데이터 저장소 구독이 필요합니다.</span><br><span>데이터 저장소 구독은<br><p class="blue">[내정보 > 데이터저장소 > 구독]</p>에서 구독할 수 있습니다.</span>`,
                      firstButtonClickFunction: () => {
                        navigate("/more/data_save_1");
                      },
                      secondButtonClickFunction: () => {},
                      button_text_1: "취소",
                      button_text_2: "구독",
                    });
                  });
              } else {
                document
                  .querySelectorAll(".excel_downloader")[0]
                  .setAttribute("href", setDownloader());
              }
            } else {
              createPopup({
                type: "confirm",
                title: "조회 실패",
                content: data.alert,
              });
            }
          });
      }

      function myPermission(el, teamworkId) {
        fetch(`https://api.olapse.fifteenh.io/api/teamwork/member?id=${teamworkId}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            data.data.teamwork_users.forEach((item, idx) => {
              if (item.user_id == myId) {
                if (item.is_leader == 1) {
                  myPermissionInfo = "팀장";
                } else if (item.is_sub_leader == 1) {
                  myPermissionInfo = "부팀장";
                } else {
                  myPermissionInfo = "팀원";
                }
                el.setAttribute("permission", myPermissionInfo);
              }
            });
          });
      }
    }

    function dataRefresh() {
      //최근순, 견적 높은 순
      document
        .querySelectorAll(".select_sort select")[0]
        .addEventListener("change", function () {
          requestData.sort = this.querySelectorAll("option:checked")[0].value;
          reqDataList();
        });
    }

    function getMemberProfileV2(id, index) {
      fetch(`https://api.olapse.fifteenh.io/api/member/detail?user_id=${id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const wrapper = document.querySelectorAll(
            "div.date_detail_3 div.player_row div.player_row_inner ul"
          )[0];
          data.data.user.profile_url &&
            (wrapper.querySelectorAll("li")[
              index
            ].style.background = `url('${data.data.user.profile_url}') no-repeat`);
        });
    }

    function initCalendar() {
      const calendarEl = document.querySelectorAll(".fullcal_wrapper")[0];
      const calendar = new FullCalendar.Calendar(calendarEl, {
        plugins: [dayGridPlugin, listPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dateClick: function (info) {
          if (document.querySelectorAll(".fc_actived_day")[0]) {
            document
              .querySelectorAll(".fc_actived_day")
              .forEach((item, idx) => {
                item.classList.remove("fc_actived_day");
              });
          }
          info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
          document.querySelectorAll(
            "div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active span"
          )[0].textContent = info.dateStr;
        },
      });

      calendar.setOption("locale", "ko");
      calendar.render();
      document
        .querySelectorAll(
          "div.data_wrapper > div.data_select_row > div.data_info > span"
        )[0]
        .addEventListener("click", function () {
          document.querySelectorAll(".popup_wrapper_2")[0].style.display =
            "flex";
          calendar.render();
        });
      //적용
      document
        .querySelectorAll(".submit_date_popup")[0]
        .addEventListener("click", function () {
          requestData.start_date =
            document.querySelectorAll(".start_time_data")[0].textContent;
          requestData.end_date =
            document.querySelectorAll(".end_time_data")[0].textContent;
          document.querySelectorAll(
            "div.data_wrapper > div.data_select_row > div.data_info > span"
          )[0].textContent = `${requestData.start_date} ~ ${requestData.end_date} (${getDateDifference(requestData.start_date, requestData.end_date)}일)`;
          reqDataList();
          document.querySelectorAll(".popup_wrapper_2")[0].style.display =
            "none";
        });
      document
        .querySelectorAll(".hide_date_popup")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(".popup_wrapper_2")[0].style.display =
            "none";
        });
    }
    function defultDateSet() {
      (document.querySelectorAll(
        "div.data_wrapper > div.data_select_row > div.data_info > span"
      )[0].textContent = `${lastWeek()} ~ ${getToday()} (7일)`),
        (document.querySelectorAll(".start_time_data")[0].textContent =
          lastWeek()),
        (document.querySelectorAll(".end_time_data")[0].textContent =
          getToday());
    }

    //상태값 선택
    function toggleStatus() {
      document
        .querySelectorAll(".status_filter_button")[0]
        .addEventListener("click", function () {
          document.querySelectorAll(".status_select_popup")[0].style.display =
            "flex";
        });

      document
        .querySelectorAll(
          "div.status_select_popup div.status_select_popup_box > ul:nth-of-type(1) li"
        )
        .forEach((item, idx) => {
          item.addEventListener("click", function () {
            if (this.classList.contains("active")) {
              this.classList.remove("active");
            } else {
              this.classList.add("active");
            }
          });
        });
      document
        .querySelectorAll(".status_select_popup_confirm")[0]
        .addEventListener("click", function () {
          requestData.status = [];
          if (
            document.querySelectorAll(
              "div.status_select_popup div.status_select_popup_box > ul:nth-of-type(1) li.active"
            )[0]
          ) {
            document
              .querySelectorAll(
                "div.status_select_popup div.status_select_popup_box > ul:nth-of-type(1) li.active"
              )
              .forEach((item, idx) => {
                requestData.status.push(item.getAttribute("status"));
              });
          } else {
            requestData.status.push("");
          }

          document.querySelectorAll(
            "div.status_select_popup"
          )[0].style.display = "none";
          reqDataList();
        });
    }

    function init() {
      defultDateSet();
      reqDataList();
      initCalendar();
      dataRefresh();
      toggleStatus();
      searchModuleFunction();
      filteringBtns(
        "div.popup_wrapper_2 div.date_picker_box div.time_col div.col",
        "active"
      );
    }
    imgViewerFunctionSetting();

    //일정공유 닫기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll("div.share_popup_wrapper")[0].style.display =
          "none";
      });
    //일정공유하기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        shareExcute();
      });
  }, []);

  return (
    <>
      <div className="popup_wrapper_2">
        <div className="date_picker_box">
          <h1>일시 설정</h1>
          <div className="time_col">
            <div className="time_row start">
              <div className="col active">
                <span className="start_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
            <div className="time_row end">
              <div className="col">
                <span className="end_time_data" />
                <img src="/assets/images/icon_cal_g.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="fullcal_wrapper"></div>
          <ul className="button_wrapper">
            <li className="hide_date_popup">취소</li>
            <li className="blue submit_date_popup">적용</li>
          </ul>
        </div>
        <div className="background_black" />
      </div>
      <a style={{ display: "none" }} className="excel_downloader" download />

      <div className="status_select_popup">
        <div className="status_select_popup_box">
          <h1>상태별 보기</h1>
          <ul>
            <li className="active" status={0}>
              요청
            </li>
            <li className="active" status={1}>
              수락
            </li>
            <li className="active" status={2}>
              완료
            </li>
            <li className="active" status={5}>
              보류
            </li>
            <li className="active" status={10}>
              취소
            </li>
          </ul>
          <ul className="status_select_popup_button_list">
            <li className="status_select_popup_confirm">확인</li>
          </ul>
        </div>
        <div className="status_select_popup_background_black" />
      </div>
    </>
  );
};

const getDateDifference = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  return (endDate - startDate) / (1000 * 60 * 60 * 24);
};

export default DatePicker;
