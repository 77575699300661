// 2022-02-24 인수인계
// 기능 : 일정관련 기능을 모아놓은 페이지입니다.

import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import { getParameterByName, filteringBtns } from "../shared/script.js";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";

let uploadedfileArr = [];

const disableAllPopup = () => {
  document.querySelectorAll(".right_popup").forEach((item, idx) => {
    item.style.display = "none";
  });
};

//일정 보류
const scheduleStay = (scheduleId) => {
  createPopup({
    type: "custom_select_2",
    title: "일정 보류",
    content: "일정을 보류 상태로 변경하시겠습니까?",
    button_text_1: "취소",
    button_text_2: "확인",
    firstButtonClickFunction: () => {
      let formdata = new FormData();
      formdata.append("schedule_id", scheduleId);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/hold`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          _animationDisappearBottomUp(
            document.querySelectorAll(".popup_box")[0]
          );
          if (data.success == true) {
            setTimeout(() => {
              createPopup({
                type: "custom",
                title: "일정 보류 완료",
                content: "일정이 보류되었습니다.",
                custom: () => {
                  if (location.pathname == "/chat") {
                    disableAllPopup();
                  } else {
                    window.location.reload(true);
                  }
                },
              });
            }, 350);
          } else {
            setTimeout(() => {
              createPopup({
                type: "reTry",
                title: "일정 보류 실패",
                content: data.alert,
              });
            }, 350);
          }
        });
    },
    secondButtonClickFunction: () => {},
  });
};
//일정 반환
const scheduleReturn = (scheduleId) => {
  createPopup({
    type: "custom_select_2",
    title: "일정 반환",
    content: "일정을 수락 전 상태로 되돌리시겠습니까?",
    button_text_1: "취소",
    button_text_2: "확인",
    firstButtonClickFunction: () => {
      let formdata = new FormData();
      formdata.append("schedule_id", scheduleId);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/return`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          _animationDisappearBottomUp(
            document.querySelectorAll(".popup_box")[0]
          );
          if (data.success == true) {
            setTimeout(() => {
              createPopup({
                type: "custom",
                title: "일정 반환 완료",
                content: "일정이 반환되었습니다.",
                custom: () => {
                  if (location.pathname == "/chat") {
                    disableAllPopup();
                  } else {
                    window.location.reload(true);
                  }
                },
              });
            }, 350);
          } else {
            setTimeout(() => {
              createPopup({
                type: "reTry",
                title: "일정 반환 실패",
                content: data.alert,
              });
            }, 350);
          }
        });
    },
    secondButtonClickFunction: () => {},
  });
};
//일정 취소
const scheduleCancel = (scheduleId) => {
  createPopup({
    type: "custom_select_2",
    title: "일정 취소",
    content: "일정을 취소 하시겠습니까?",
    button_text_1: "취소",
    button_text_2: "확인",
    firstButtonClickFunction: () => {
      let formdata = new FormData();
      formdata.append("schedule_id", scheduleId);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/cancel`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          _animationDisappearBottomUp(
            document.querySelectorAll(".popup_box")[0]
          );
          if (data.success == true) {
            setTimeout(() => {
              createPopup({
                type: "custom",
                title: "일정 취소 완료",
                content: "일정이 취소되었습니다.",
                custom: () => {
                  if (location.pathname == "/chat") {
                    disableAllPopup();
                  } else {
                    window.location.reload(true);
                  }
                },
              });
            }, 350);
          } else {
            setTimeout(() => {
              createPopup({
                type: "reTry",
                title: "일정 취소 실패",
                content: data.alert,
              });
            }, 350);
          }
        });
    },
    secondButtonClickFunction: () => {},
  });
};

//일정 삭제
const scheduleRemove = (scheduleId) => {
  createPopup({
    type: "custom_select_2",
    title: "일정 삭제",
    content: "일정을 삭제 하시겠습니까?",
    button_text_1: "취소",
    button_text_2: "확인",
    firstButtonClickFunction: () => {
      fetch(
        `https://api.olapse.fifteenh.io/api/teamwork/schedule/delete?schedule_id=${scheduleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          _animationDisappearBottomUp(
            document.querySelectorAll(".popup_box")[0]
          );
          if (data.success == true) {
            setTimeout(() => {
              createPopup({
                type: "custom",
                title: "일정 삭제 완료",
                content: "일정이 삭제되었습니다.",
                custom: () => {
                  // window.location.reload(true);
                  if (location.pathname == "/chat") {
                    disableAllPopup();
                  } else {
                    window.location.reload(true);
                  }
                },
              });
            }, 350);
          } else {
            setTimeout(() => {
              createPopup({
                type: "reTry",
                title: "일정 삭제 실패",
                content: data.alert,
              });
            }, 350);
          }
        });
    },
    secondButtonClickFunction: () => {},
  });
};
let isModify = false,
  deleteFileIdArr = [];
//일정 수정
const editSchedule = (scheduleId) => {
  isModify = true;
  deleteFileIdArr = [];
  fetch(
    `https://api.olapse.fifteenh.io/api/teamwork/schedule/detail?id=${scheduleId}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      document.querySelectorAll(".date_picker_container")[0].textContent =
        data.data.schedule.begin_date;
      if (data.data.schedule.end_date) {
        document.querySelectorAll(".date_picker_container")[1].textContent =
          data.data.schedule.end_date;
        document.querySelectorAll(".date_picker_container")[1].style.display =
          "flex";
      }

      document.querySelector("#content").value = data.data.schedule.contents;
      document.querySelector("#location").value = data.data.schedule.place;
      document.querySelector("#phone_number").value = data.data.schedule.phone;
      document.querySelector("#price").value = data.data.schedule.price;
      if (data.data.schedule_files.length !== 0) {
        data.data.schedule_files.forEach((item, idx) => {
          const htmlString = `<li fileIdx="${item.id}" class="added_file"> <p class="file_del_button new_file_del_button"></p> <div class="file_icon"></div> <span class="file_name">${item.file_url}</span> <span class="file_size">${item.file_size}kb</span> </li>`;
          document
            .querySelectorAll(".d_8 .b_submit_file_list")[0]
            .insertAdjacentHTML("afterbegin", htmlString);
          document
            .querySelectorAll(".new_file_del_button")[0]
            .addEventListener("click", function () {
              deleteFileIdArr.push(item.id);
            });
          document
            .querySelectorAll(".new_file_del_button")[0]
            .classList.remove("new_file_del_button");
        });
      }
    });
};
// window.addEventListener("load", function () {
//     document.querySelectorAll(".date_submit_button")[0].addEventListener("click", function () {
//         if (isModify == true) {
//             editScheduleExcute(document.querySelectorAll(".edit_schedule")[0].getAttribute("schedule_id"));
//         }
//     });
//     document.querySelectorAll(".edit_schedule")[0].addEventListener("click", function () {
//         editSchedule(this.getAttribute("schedule_id"));
//     });
//     //일정공유 닫기
//     document.querySelectorAll("div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(1)")[0].addEventListener("click", function () {
//         document.querySelectorAll("div.share_popup_wrapper")[0].style.display = "none";
//     });
//     //일정공유하기
//     document.querySelectorAll("div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(2)")[0].addEventListener("click", function () {
//         shareExcute();
//     });
// });

const editScheduleExcute = (scheduleId) => {
  let formdata = new FormData();
  formdata.append(
    "begin_date",
    document.querySelectorAll(".date_picker_container")[0].textContent
  );
  formdata.append(
    "end_date",
    document.querySelectorAll(".date_picker_container")[1].textContent
  );
  formdata.append("contents", document.querySelector("#content").value);
  formdata.append("place", document.querySelector("#location").value);
  formdata.append("phone", document.querySelector("#phone_number").value);
  formdata.append("price", document.querySelector("#price").value);
  formdata.append("is_individual", "0");
  formdata.append("teamwork_id", getParameterByName("idx"));
  formdata.append("schedule_id", scheduleId);
  if (deleteFileIdArr.length !== 0) {
    deleteFileIdArr.forEach((item) => {
      formdata.append("delete_files[]", item);
    });
  }

  if (uploadedfileArr.length !== 0) {
    uploadedfileArr.forEach((item, idx) => {
      formdata.append("files[]", item, item.name);
    });
  }

  fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/modify`, {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        createPopup({
          type: "custom",
          title: "수정 완료",
          content: "일정이 수정되었습니다.",
          custom: () => {
            disableAllPopup();
          },
        });
      } else {
        createPopup({
          type: "reTry",
          title: "수정 실패",
          content: data.alert,
        });
      }
    });
};

const clearScheduleInfo = () => {
  document.querySelectorAll(".date_picker_container")[0].textContent = "";
  document.querySelectorAll(".date_picker_container")[1].textContent = "";
  document.querySelector("#content").value = "";
  document.querySelector("#location").value = "";
  document.querySelector("#phone_number").value = "";
  document.querySelector("#price").value = "";
  document.querySelectorAll(".date_picker_container")[1].style.display = "none";

  if (document.querySelectorAll(".d_8 .added_file")[0]) {
    document.querySelectorAll(".d_8 .added_file").forEach((item, idx) => {
      item.remove();
    });
  }
};

const msgShareExcute = (index) => {
  //작업중
  const formdata = new FormData();
  formdata.append(
    "id",
    document
      .querySelectorAll(".share_popup_wrapper_2 .share_items.active")[0]
      .getAttribute("idx")
  );
  formdata.append("message_id", index);
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/message/share`, {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        document.querySelectorAll("div.chat_long_menu").forEach((item, idx) => {
          item.style.display = "none";
        });
        createPopup({
          type: "confirm",
          title: "공유 성공",
          content: "메세지를 공유했습니다.",
        });
      } else {
        createPopup({
          type: "reTry",
          title: "공유 실패",
          content: data.alert,
        });
      }
    });
  document
    .querySelectorAll(".share_popup_box_button_wrapper button")[2]
    .click();
};

//일정 공유
const scheduleShare = () => {
  document.querySelectorAll("div.share_popup_wrapper")[0].style.display =
    "flex";
  inner(`https://api.olapse.fifteenh.io/api/teamwork`);
  function inner(url) {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(".share_team_work_list")[0].textContent = "";
        data.data.teamworks.forEach((item, idx) => {
          if (item.is_leader == 1 || item.is_sub_leader == 1) {
            const htmlString = `<li idx="${
              item.id
            }" class="share_items"> <div class="share_team_work_list_col"> <p style="background:url('${
              item.image_url
                ? item.image_url
                : "/assets/images/icon_teamwork_basic.svg"
            }') no-repeat" class="share_team_work_list"></p> <span>${
              item.title
            }</span> </div> </li>`;
            document
              .querySelectorAll(".share_team_work_list")[0]
              .insertAdjacentHTML("beforeend", htmlString);
          }
        });
        if (data.data.teamworks.next_page_url) {
          inner(data.data.teamworks.next_page_url);
        } else {
          document.querySelectorAll(".share_items").forEach((item, idx) => {
            if (item.getAttribute("idx") == getParameterByName("idx")) {
              item.style.display = "none";
            }
          });
          filteringBtns(".share_popup_wrapper_1 .share_items", "active");
        }
      });
  }
};

//메세지 공유
const magShare = (idx) => {
  document.querySelectorAll("div.share_popup_wrapper_2")[0].style.display =
    "flex";
  document
    .querySelectorAll(
      ".share_popup_wrapper_2 .share_popup_box_button_wrapper button"
    )[1]
    .setAttribute("idx", idx);
  if (document.querySelectorAll(".share_popup_wrapper_2 share_items")[0]) {
    document
      .querySelectorAll(".share_popup_wrapper_2 share_items")
      .forEach((item, idx) => {
        item.remove();
      });
  }

  inner(`https://api.olapse.fifteenh.io/api/teamwork`);
  function inner(url) {
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(
          ".share_popup_wrapper_2 .share_team_work_list"
        )[0].textContent = "";
        data.data.teamworks.forEach((item, idx) => {
          const htmlString = `<li idx="${
            item.id
          }" class="share_items"> <div class="share_team_work_list_col"> <p style="background:url('${
            item.image_url
              ? item.image_url
              : "/assets/images/icon_teamwork_basic.svg"
          }') no-repeat" class="share_team_work_list"></p> <span>${
            item.title
          }</span> </div> </li>`;
          document
            .querySelectorAll(".share_popup_wrapper_2 .share_team_work_list")[0]
            .insertAdjacentHTML("beforeend", htmlString);
        });
        if (data.data.teamworks.next_page_url) {
          inner(data.data.teamworks.next_page_url);
        } else {
          document
            .querySelectorAll(".share_popup_wrapper_2 .share_items")
            .forEach((item, idx) => {
              if (item.getAttribute("idx") == getParameterByName("idx")) {
                item.style.display = "none";
              }
            });
          filteringBtns(".share_popup_wrapper_2 .share_items", "active");
        }
      });
  }
};

const shareExcute = () => {
  if (document.querySelectorAll(".share_items.active")[0]) {
    let formdata = new FormData();
    formdata.append(
      "schedule_id",
      document
        .querySelectorAll(".edit_schedule")[0]
        .getAttribute("data-schedule_id")
    );
    formdata.append(
      "teamwork_id",
      document.querySelectorAll(".share_items.active")[0].getAttribute("idx")
    );
    fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/share`, {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          createPopup({
            type: "custom",
            title: "공유 성공",
            content: "일정을 공유했습니다.",
            custom: () => {
              disableAllPopup();
              document.querySelectorAll(
                ".share_popup_wrapper_1"
              )[0].style.display = "none";
            },
          });
        } else {
          createPopup({
            type: "reTry",
            title: "공유 실패",
            content: data.alert,
          });
        }
      });
  } else {
    createPopup({
      type: "reTry",
      title: "공유 실패",
      content: "공유할 팀워크를 선택해주세요.",
    });
  }
};
//결제타입 변경
const changePaymentType = (id) => {
  alert("타입변경 API확인중");
};

//정산
const paymentShare = (id) => {
  let formdata = new FormData();
  let scheduleIdArr = [];
  scheduleIdArr.push(id);
  formdata.append("schedule_id[]", scheduleIdArr);
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/calculate/complete`, {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        createPopup({
          type: "custom",
          title: "정산 성공",
          content: "정산이 완료되었습니다.",
          custom: () => {
            disableAllPopup();
          },
        });
      } else {
        createPopup({
          type: "confirm",
          title: "정산 실패",
          content: data.alert,
        });
      }
    });
};

//일정 결제하기
const payment = (id) => {
  document.querySelectorAll(".chat_loadingPay")[0].style.display = "flex";
  if (
    String(document.querySelector("#payment_name").value).length !== 0 &&
    String(document.querySelector("#payment_card_number").value).length !== 0 &&
    String(document.querySelector("#payment_due_date").value).length !== 0
  ) {
    let formdata = new FormData();

    formdata.append("name", document.querySelector("#payment_name").value);
    formdata.append("schedule_id", id);
    formdata.append(
      "card_num",
      document.querySelector("#payment_card_number").value
    );
    formdata.append("price", document.querySelector("#payment_price").value);

    let dueDate = document.querySelector("#payment_due_date").value;
    formdata.append("card_expire", dueDate.substr(2, 2) + dueDate.substr(0, 2));

    formdata.append(
      "card_quota",
      document.querySelectorAll("#payment_month option:checked")[0].value
    );
    fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/payment`, {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(".chat_loadingPay")[0].style.display = "none";
        if (data.success == true) {
          createPopup({
            type: "custom",
            title: "결제 완료",
            content: "결제가 완료되었습니다.",
            custom: () => {
              disableAllPopup();
            },
          });
        } else {
          createPopup({
            type: "reTry",
            title: "결제 실패",
            content: data.alert,
          });
        }
      });
  } else {
    createPopup({
      type: "reTry",
      title: "결제 실패",
      content: "모든 필수 정보를 입력해주세요.",
    });
  }
};

export {
  msgShareExcute,
  magShare,
  scheduleStay,
  scheduleReturn,
  scheduleCancel,
  scheduleRemove,
  editSchedule,
  editScheduleExcute,
  clearScheduleInfo,
  scheduleShare,
  shareExcute,
  changePaymentType,
  paymentShare,
  payment,
};
