// 2022-02-24 인수인계
// 기능 : 정보수정 페이지입니다.

import React, {useEffect, useState} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import createPopup from "./createPopup";

const TeamCode = () => {
  const navigate = useNavigate();

    const editProfile = () => {
      console.log(document.querySelector("#team_code").value)
      if (
        String(document.querySelector("#team_code").value).length !== 0
      ) {
        let formdata = new FormData();
        formdata.append("code", document.querySelector("#team_code").value);

        fetch(`https://api.olapse.fifteenh.io/api/team/code`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "코드 등록 완료",
                content: "팀코드 등록이 완료되었습니다.",
                custom: () => {
                  navigate('/more')
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "코드 등록 실패",
                content: "정상적인 코드가 아닙니다.<br/>" +
                    "다시 확인 후 이용해주시길 바랍니다.",
              });
            }
          });
      } else {
        createPopup({
          type: "reTry",
          title: "수정실패",
          content: "모든 정보를 입력해주세요.",
        });
      }
    };

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="팀코드 등록" />
        <div className="edit_wrapper">
          <div className="profile_img_input_wrapper">
            <div className="edit_info_wrapper">
              <div className="row">
                <h1>팀코드</h1>
                <input
                  id="team_code"
                  placeholder="초대 코드를 입력해주세요."
                  type="text"
                />
              </div>
              <button className="profile_edit_complete_button" onClick={() => editProfile()}>
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TeamCode;
