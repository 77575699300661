// 2022-02-24 인수인계
// 기능 : 라우터입니다.

import React from "react";
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import FindIdFirst from "./pages/find_id_1";
import FindIdSecond from "./pages/find_id_2";
import FindPassFirst from "./pages/find_pass_1";
import FindPassSecond from "./pages/find_pass_2";
import Agree from "./pages/agree";
import Phone from "./pages/phone";
import Phone2 from "./pages/phone_2";
import Account1 from "./pages/account_1";
import Account2 from "./pages/account_2";
import Main from "./pages/Main";
import More from "./pages/More";
import "./assets/css/routerEffect.css";
import ChangePhone from "./pages/change_phone";
import ChangeId from "./pages/change_id";
import ChangePassword from "./pages/change_password";
import Exit from "./pages/exit";
import Chat from "./pages/chat";
import Cal from "./pages/cal";
import DateDetail from "./pages/date_detail";
import Create from "./pages/create";
import Create2 from "./pages/create_2";
import CalAll from "./pages/cal_all";

const Router = () => {
    const location = useLocation();

    return (
        <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/find_id_1" element={<FindIdFirst />} />
            <Route path="/find_id_2" element={<FindIdSecond />} />
            <Route path="/find_pass_1" element={<FindPassFirst />} />
            <Route path="/find_pass_2" element={<FindPassSecond />} />
            <Route path="/agree" element={<Agree />} />
            <Route path="/cal" element={<Cal />} />
            <Route path="/phone" element={<Phone />} />
            <Route path="/phone_2" element={<Phone2 />} />
            <Route path="/account_1" element={<Account1 />} />
            <Route path="/account_2" element={<Account2 />} />
            <Route path="/main" element={<Main />} />
            <Route path="/more" element={<More />} />
            <Route path="/more/my_info" element={<More />} />
            <Route path="/more/schedule_list" element={<More />} />
            <Route path="/more/setting" element={<More />} />
            <Route path="/change_phone" element={<ChangePhone />} />
            <Route path="/change_id" element={<ChangeId />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/exit" element={<Exit />} />
            <Route path="/more/customer_center/0" element={<More />} />
            <Route path="/more/customer_center/1" element={<More />} />
            <Route path="/more/faq_detail" element={<More />} />
            <Route path="/more/notice" element={<More />} />
            <Route path="/more/notice_detail" element={<More />} />
            <Route path="/more/alarm" element={<More />} />
            <Route path="/more/photo" element={<More />} />
            <Route path="/more/file" element={<More />} />
            <Route path="/more/date" element={<More />} />
            <Route path="/more/data_save_1" element={<More />} />
            <Route path="/more/data_save_2" element={<More />} />
            <Route path="/more/data_save_3" element={<More />} />
            <Route path="/more/subs" element={<More />} />
            <Route path="/more/edit_info" element={<More />} />
            <Route path="/more/team/code" element={<More />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/date_detail" element={<DateDetail />} />
            <Route path="/create" element={<Create />} />
            <Route path="/create2" element={<Create2 />} />
            <Route path="/more/submit_b" element={<More />} />
            <Route path="/more/card" element={<More />} />
            <Route path="/more/payment_detail" element={<More />} />
            <Route path="/more/payment_detail_2" element={<More />} />
            <Route path="/cal_all" element={<CalAll />} />
            <Route path="/more/confirm" element={<More />} />
        </Routes>
    );
};

export default Router;
