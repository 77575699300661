// 2022-02-24 인수인계
// 기능 : 일정 모아보기 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import {
  noData,
  getParameterByName,
  numberWithCommas,
  imgViewerFunctionSetting,
  filteringBtns,
} from "../shared/script";
import TeamworkList from "./teamwork_list";
import {
  readLastMsg,
  scrollBottom,
  sendMsgExcute,
  memberDetailFunction,
  updateScheduleData,
  toggleSubMenu,
  fileUploadChat,
  enterTeamwork,
} from "../shared/chat.js";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import TeamworkModule from "./teamworkModules";
import createPopup from "./createPopup";
import { shareExcute } from "../shared/schedule";

const DateGather = () => {
  let keyword = "";
  let flow = 1,
    chkNumber = 3,
    lastChk = false;
  const navigate = useNavigate();

  let prams = {
    url: `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?page=${flow}&keyword=${keyword}`,
    teamwork_id: getParameterByName("idx")
      ? `&teamwork_id=${getParameterByName("idx")}`
      : null,
    teamId: getParameterByName("idx") ? `${getParameterByName("idx")}` : null,
  };

  const getDateList = (targetItem) => {
    function myPermission(el, teamworkId) {
      let myId, myPermissionInfo;

      fetch("https://api.olapse.fifteenh.io/api/member", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          myId = data.data.my_info.id;
          fetch(`https://api.olapse.fifteenh.io/api/teamwork/member?id=${teamworkId}`, {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              data.data.teamwork_users.forEach((item, idx) => {
                if (item.user_id == myId) {
                  if (item.is_leader == 1) {
                    myPermissionInfo = "팀장";
                  } else if (item.is_sub_leader == 1) {
                    myPermissionInfo = "부팀장";
                  } else {
                    myPermissionInfo = "팀원";
                  }
                  el.addEventListener("click", function () {
                    _animateRightModule(
                      true,
                      document.querySelectorAll(".d_4")[0]
                    );
                    updateScheduleData(
                      this.getAttribute("idx"),
                      myPermissionInfo
                    );
                    document
                      .querySelectorAll(".common_more_wrapper li")
                      .forEach((item2, idx2) => {
                        item2.setAttribute(
                          "data-schedule_id",
                          this.getAttribute("idx")
                        );
                      });
                    document
                      .querySelectorAll(".edit_schedule")[0]
                      .setAttribute(
                        "data-schedule_id",
                        this.getAttribute("idx")
                      );
                  });
                }
              });
            });
        });
    }
    // filteringBtns("div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li", "active");
    filteringBtns(
      "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li",
      "active"
    );
    //일정 완료처리
    let paymentType1Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_1 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType1Chk) {
          paymentType1Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_1 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/complete`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType1Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "일정완료",
                    content: "일정을 완료하였습니다.",
                    custom: () => {
                      // location.reload(true);
                      var pel = document.querySelectorAll(
                        ".payment_select_popup_wrapper_1"
                      )[0];
                      _animationDisappearBottomUp(
                        pel.querySelectorAll(".payment_select_popup_box")[0]
                      );
                      _animateRightModule(
                        false,
                        document.querySelectorAll(".d_4")[0]
                      );
                      setTimeout(() => {
                        pel.style.display = "none";
                      }, 400);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "일정완료 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType1Chk = true;
            createPopup({
              type: "reTry",
              title: "일정 완료 불가",
              content: "일정을 하나 이상 선택해주세요.",
            });
          }
        }
      });

    //일정 완료처리
    let paymentType2Chk = true;
    document
      .querySelectorAll(
        ".payment_select_popup_wrapper_2 .payment_select_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        if (paymentType2Chk) {
          paymentType2Chk = false;
          if (
            document.querySelectorAll(
              "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
            )[0]
          ) {
            let formdata = new FormData();
            formdata.append("schedule_id", this.getAttribute("schedule_index"));
            document
              .querySelectorAll(
                "div.payment_select_popup_wrapper_2 div.payment_select_popup_box ul li.active span"
              )
              .forEach((item, idx) => {
                formdata.append("payment_type[]", item.textContent);
              });
            fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/type/modify`, {
              method: "POST",
              body: formdata,
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (data) {
                paymentType2Chk = true;
                if (data.success == true) {
                  createPopup({
                    type: "custom",
                    title: "변경완료",
                    content: "결제타입을 변경하였습니다.",
                    custom: () => {
                      location.reload(true);
                    },
                  });
                } else {
                  createPopup({
                    type: "reTry",
                    title: "타입변경 실패",
                    content: data.alert,
                  });
                }
              });
          } else {
            paymentType2Chk = true;
            createPopup({
              type: "reTry",
              title: "타입 변경 불가",
              content: "타입을 하나 이상 선택해주세요.",
            });
          }
        }
      });
    const appendTarget = document.querySelectorAll(".date_arr_list_wrapper")[0];
    document
      .querySelectorAll(".date_arr_list_wrapper_row")
      .forEach((item, idx) => {
        item.remove();
      });
    inner(
      prams.teamwork_id ? prams.url + prams.teamwork_id : prams.url,
      prams.teamwork_id ? prams.teamwork_id : ""
    );

    function inner(url, teamId) {
      document.querySelectorAll(".chat_loadingDate")[0].style.display = "flex";
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          if (response.status == 429) {
            setTimeout(() => {
              window.location.reload();
            }, 10000);
          }
          return response.json();
        })
        .then(function (data) {
          lastChk = flow == data.data.schedules.last_page ? true : false;

          let settingType =
            targetItem == false
              ? true
              : targetItem == "all" &&
                document
                  .querySelectorAll("div.teamword_list ul li.all")[0]
                  .classList.contains("active")
              ? true
              : `&teamwork_id=${document
                  .querySelectorAll("div.teamword_list ul li.active")[0]
                  .getAttribute("idx")}` == teamId
              ? true
              : false;
          if (settingType) {
            if (document.querySelectorAll("div.date_filter_row select")[0]) {
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[0].text = `전체(${data.data.schedules.total})`;
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[1].text = `요청(${data.data.request_schedule_count})`;
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[2].text = `수락(${data.data.accept_schedule_count})`;
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[3].text = `완료(${data.data.complete_schedule_count})`;
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[4].text = `보류(${data.data.hold_schedule_count})`;
              document.querySelectorAll(
                "div.date_filter_row select option"
              )[5].text = `취소(${data.data.cancel_schedule_count})`;
            }
            if (data.data.schedules.data.length !== 0) {
              noData(false);
              data.data.schedules.data.forEach(function (item, idx) {
                let header, className;
                if (item.status == 0) {
                  //요청
                  (header = `<h1>요청</h1><span></span>`),
                    (className = "status_1");
                } else if (item.status == 1) {
                  //수락
                  (header = `<h1>수락</h1><span>${item.name}</span>`),
                    (className = "status_2");
                } else if (item.status == 2) {
                  //완료
                  (header = `<h1>완료</h1><span></span>`),
                    (className = "status_3");
                } else if (item.status == 5) {
                  //보류
                  (header = `<h1>보류</h1><span></span>`),
                    (className = "status_4");
                } else if (item.status == 10) {
                  //취소
                  (header = `<h1>취소</h1><span></span>`),
                    (className = "status_5");
                }
                const htmlString = `<div class="date_arr_list_wrapper_row new_date_arr_list_wrapper_row"> <h1 class="created_at_date">${
                  item.begin_date.split(" ")[0]
                }</h1> <ul> <li teamworkId=${item.teamwork_id} idx="${
                  item.id
                }" class="${className} active"> ${header} <div class="date_arr_text_row"> <span>${
                  item.contents
                }</span> <span>${numberWithCommas(
                  item.price
                )}원</span> </div> <span class="data_arr_created_at"> ${
                  item.begin_date
                }~<br> ${
                  item.end_date ? item.end_date : "-"
                } </span> </li> </ul> </div>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);

                document
                  .querySelectorAll(
                    "div.new_date_arr_list_wrapper_row div.date_arr_list_wrapper_row ul li"
                  )
                  .forEach((item, idx) => {
                    myPermission(item, item.getAttribute("teamworkId"));
                  });
                document
                  .querySelectorAll(".new_date_arr_list_wrapper_row")[0]
                  .classList.remove("new_date_arr_list_wrapper_row");
              });
              insert();
              function insert() {
                document
                  .querySelectorAll(".date_arr_list_wrapper_row")
                  .forEach((item, idx) => {
                    if (
                      item.nextElementSibling &&
                      item.querySelectorAll(".created_at_date")[0]
                        .textContent ==
                        item.nextElementSibling.querySelectorAll(
                          ".created_at_date"
                        )[0].textContent
                    ) {
                      item
                        .querySelectorAll("ul")[0]
                        .insertAdjacentElement(
                          "beforeend",
                          item.nextElementSibling
                            .querySelectorAll("li")[0]
                            .cloneNode(true)
                        );
                      item.nextElementSibling.remove();
                      insert();
                    }
                  });
              }
            } else {
              noData(true);
            }

            if (flow < chkNumber && !lastChk) {
              flow++;
              prams.url = `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?page=${flow}&keyword=${keyword}`;
              inner(
                prams.teamwork_id ? prams.url + prams.teamwork_id : prams.url,
                prams.teamwork_id ? prams.teamwork_id : ""
              );
            } else {
              chkNumber = flow + 2;
              document.querySelectorAll(".chat_loadingDate")[0].style.display =
                "none";
            }
          }
        });
    }

    //스크롤 하단 체크
    function bottomChk() {
      let scrollTop = document.documentElement.scrollTop,
        innerHeight = window.innerHeight,
        scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

      if (scrollTop + innerHeight >= scrollHeight) {
        return true;
      } else {
        return false;
      }
    }

    //추가 페이지 있을 시 추가 세팅
    function addPage() {
      if (bottomChk() && !lastChk && flow !== 1) {
        flow++;
        prams.url = `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?page=${flow}&keyword=${keyword}`;
        inner(
          prams.teamwork_id ? prams.url + prams.teamwork_id : prams.url,
          prams.teamwork_id ? prams.teamwork_id : ""
        );
      }
    }
    document.addEventListener("scroll", function () {
      addPage();
    });
  };

  const sortStatus = () => {
    document
      .querySelectorAll(".date_filter_row select")[0]
      .addEventListener("change", function () {
        document
          .querySelectorAll(".date_arr_list_wrapper_row li")
          .forEach((item, idx) => {
            item.classList.add("active");
          });
        if (
          this.querySelectorAll("option:checked")[0].text.split("(")[0] ==
          "요청"
        ) {
          document
            .querySelectorAll(".date_arr_list_wrapper_row li")
            .forEach((item, idx) => {
              if (!item.classList.contains("status_1")) {
                item.classList.remove("active");
              }
            });
        } else if (
          this.querySelectorAll("option:checked")[0].text.split("(")[0] ==
          "수락"
        ) {
          document
            .querySelectorAll(".date_arr_list_wrapper_row li")
            .forEach((item, idx) => {
              if (!item.classList.contains("status_2")) {
                item.classList.remove("active");
              }
            });
        } else if (
          this.querySelectorAll("option:checked")[0].text.split("(")[0] ==
          "완료"
        ) {
          document
            .querySelectorAll(".date_arr_list_wrapper_row li")
            .forEach((item, idx) => {
              if (!item.classList.contains("status_3")) {
                item.classList.remove("active");
              }
            });
        } else if (
          this.querySelectorAll("option:checked")[0].text.split("(")[0] ==
          "보류"
        ) {
          document
            .querySelectorAll(".date_arr_list_wrapper_row li")
            .forEach((item, idx) => {
              if (!item.classList.contains("status_4")) {
                item.classList.remove("active");
              }
            });
        } else if (
          this.querySelectorAll("option:checked")[0].text.split("(")[0] ==
          "취소"
        ) {
          document
            .querySelectorAll(".date_arr_list_wrapper_row li")
            .forEach((item, idx) => {
              if (!item.classList.contains("status_5")) {
                item.classList.remove("active");
              }
            });
        }
        document
          .querySelectorAll(".date_arr_list_wrapper_row")
          .forEach((item, idx) => {
            toggleHideDate(item);
          });

        function toggleHideDate(element) {
          element.querySelectorAll("li.active").length == 0
            ? (element.querySelectorAll(".created_at_date")[0].style.display =
                "none")
            : (element.querySelectorAll(".created_at_date")[0].style.display =
                "flex");
        }

        if (
          document.querySelectorAll(".date_arr_list_wrapper_row li.active")
            .length == 0
        ) {
          noData(true);
        } else {
          noData(false);
        }
      });
  };

  const dateClickEv = (target) => {
    prams.teamwork_id = `&teamwork_id=${target.getAttribute("idx")}`;
    getDateList(target);
  };
  const allEv = () => {
    prams.teamwork_id = null;
    getDateList("all");
  };

  useEffect(() => {
    if (!getParameterByName("idx")) {
      getDateList(false);
    }

    sortStatus();
    const searchModuleElement = document.querySelectorAll(
      "div.top.search_module"
    )[0];
    //닫기
    document
      .querySelectorAll(".close_search_module")[0]
      .addEventListener("click", function () {
        searchModuleElement.style.display = "none";
      });
    //열기
    document
      .querySelectorAll(".open_search_module")[0]
      .addEventListener("click", function () {
        searchModuleElement.style.display = "flex";
        document
          .querySelectorAll(
            "div.top.search_module div.left div.search_input_wrapper input#search_input"
          )[0]
          .focus();
      });

    document
      .querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper input#search_input"
      )[0]
      .addEventListener("keydown", function (e) {
        if (e.keyCode == 13) {
          searchModuleElement.style.display = "none";
          keyword = document.querySelectorAll(".pc_right_area #search_input")[0]
            .value;
          prams.url = `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?keyword=${keyword}`;
          getDateList(false);
        }
      });

    document
      .querySelectorAll(".search_submit_button")[0]
      .addEventListener("click", function () {
        keyword = document.querySelectorAll(".pc_right_area #search_input")[0]
          .value;
        prams.url = `https://api.olapse.fifteenh.io/api/teamwork/all/schedule?keyword=${keyword}`;
        searchModuleElement.style.display = "none";
        getDateList(false);
      });
    document
      .querySelectorAll(
        "div.top.search_module div.left div.search_input_wrapper p.del_text"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll(
          "div.top.search_module div.left div.search_input_wrapper input#search_input"
        )[0].value = "";
      });
    imgViewerFunctionSetting();

    //일정공유 닫기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(1)"
      )[0]
      .addEventListener("click", function () {
        document.querySelectorAll("div.share_popup_wrapper")[0].style.display =
          "none";
      });
    //일정공유하기
    document
      .querySelectorAll(
        "div.share_popup_wrapper div.share_popup_box div.share_popup_box_button_wrapper button:nth-of-type(2)"
      )[0]
      .addEventListener("click", function () {
        shareExcute();
      });
  }, []);
  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="일정 모아보기" isSearch={1} isOpenMenu={1} />
        <TeamworkList clickEv={dateClickEv} allEv={allEv} />
        <div className="date_filter_row">
          <select className="cal_select">
            <option>전체</option>
            <option>요청</option>
            <option>수락</option>
            <option>완료</option>
            <option>보류</option>
            <option>취소</option>
          </select>
        </div>
        <div className="date_arr_list_wrapper" />
        <div className="no_data">
          <span>일정이 없습니다.</span>
        </div>
      </div>
      <TeamworkModule />
    </>
  );
};
export default DateGather;
