// 2022-02-24 인수인계
// 기능 : 채팅에서 사용하는 함수를 모아놓은 파일입니다.

import { useEffect } from "react";
import createPopup from "../components/createPopup";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import {
  numberWithCommas,
  getParameterByName,
  imgViewerFunction,
  lastWeek,
  getToday,
  phoneCall,
} from "../shared/script.js";
import { Navigate } from "react-router-dom";
// import { NullChannel } from "laravel-echo/dist/channel";

let startStackTimer,
  lockStack,
  teamworkId,
  myId,
  myPermissionInfo,
  calendar,
  teamworkMyId;
const imgTypeArr = ["jpg", "jpeg", "gif", "bmp", "png"];
const videoArr = [
  "mp4",
  "m4v",
  "avi",
  "asf",
  "wmv",
  "mkv",
  "ts",
  "mpg",
  "mpeg",
  "mov",
  "flv",
  "ogv",
];
let myCalFunctionParams2 = {
  startDate: lastWeek(),
  endDate: getToday(),
  teamworkUserId: null,
};
let dateSetType = false;

let myInfoSetting = setInterval(() => {
  sessionStorage.setItem("idSet", false);
  if (localStorage.getItem("token") !== null) {
    fetch("https://api.olapse.fifteenh.io/api/member", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        myId = data.data.my_info.id;
        sessionStorage.setItem("myId", myId);
        getParameterByName("idx") && showMemeberPayment();
        getParameterByName("idx") && myPermission();
      });
    clearInterval(myInfoSetting);
  }
}, 200);

const myPermission = () => {
  fetch(
    `https://api.olapse.fifteenh.io/api/teamwork/member?id=${getParameterByName(
      "idx"
    )}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        data.data.teamwork_users.forEach((item, idx) => {
          if (item.user_id == myId) {
            if (item.is_leader == 1) {
              myPermissionInfo = "팀장";
              sessionStorage.setItem("myPermissionInfo", "팀장");
            } else if (item.is_sub_leader == 1) {
              myPermissionInfo = "부팀장";
              sessionStorage.setItem("myPermissionInfo", "부팀장");
            } else {
              myPermissionInfo = "팀원";
              sessionStorage.setItem("myPermissionInfo", "팀원");
            }
            teamworkMyId = item.id;
            sessionStorage.setItem("idSet", true);
          }
        });
      }
    });
};

//메세지 읽음처리
const readLastMsg = (id, activedTeamworkId) => {
  if (id !== null) {
    let formdata = new FormData();
    formdata.append("teamwork_id", activedTeamworkId);
    formdata.append("teamwork_message_id", id);
    fetch(`https://api.olapse.fifteenh.io/api/teamwork/read`, {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const targetElement = document.querySelectorAll(".chat span")[0];
        let varUAApp = navigator.userAgent;

        if (varUAApp.match("olapse/iOS") != null) {
          if (data.data.unread_message_count !== 0) {
            window.webkit.messageHandlers.badgeRefresh.postMessage(
              data.data.unread_message_count
            );
          }
        }
        if (varUAApp.match("olapse/Android") != null) {
          window.olapse.postMessage(
            JSON.stringify({
              type: "badgeRefresh",
              badge: data.data.unread_message_count,
            })
          );
        }
        if (targetElement && data.data.unread_message_count !== 0) {
          (targetElement.style.display = "flex"),
            (targetElement.textContent =
              data.data.unread_message_count >= 100
                ? "99+"
                : data.data.unread_message_count);
        } else if (targetElement && data.data.unread_message_count == 0) {
          targetElement.style.display = "none";
        }
      });
  }
};

//팀워크 입장 처리
const enterTeamwork = (teamworkId) => {
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/enter?id=${teamworkId}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {});
};

//바닥 스크롤
const scrollBottom = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    document.querySelectorAll(".chat_data_inner")[0] &&
      document
        .querySelectorAll(".chat_data_inner")[0]
        .scrollTo(
          0,
          document.querySelectorAll(".chat_data_inner")[0].scrollHeight
        );
  } else {
    window.scrollTo(0, document.body.scrollHeight);
  }
};

//메세지 발송
function sendMsgExcute(msg, teamworkId) {
  var msgChk = false;
  if (msg.indexOf("\n") !== -1) {
    msgChk = msg.replace(/\n/g, "").length !== 0 ? true : false;
  } else if (msg.indexOf(" ") !== -1) {
    msgChk = msg.replace(/ /g, "").length !== 0 ? true : false;
  } else {
    msgChk = msg.length !== 0 ? true : false;
  }

  if (msgChk) {
    msgLock();
    if (navigator.onLine == false) {
      const htmlString = `<div class="chat_row chat_row_prev chat_notSend"> <div class="chat_row_wrapper_my"> <p>${msg}</p> <div class="chat_info_right"> <div class="notMsgBox"><button type="button" class="btn_msgDel btn_msgDelSet"><img src="/assets/images/msgDel.svg"></button><button type="button" data-reMsg="${msg}" class="btn_msgRe btn_msgReSet"><img src="/assets/images/msgRe.svg"></button></div> </div> </div> </div>`;
      document
        .querySelectorAll(".chat_data_inner")[0]
        .insertAdjacentHTML("beforeend", htmlString);

      document
        .querySelectorAll(".btn_msgDelSet")[0]
        .addEventListener("click", function () {
          this.parentNode.parentNode.parentNode.parentNode.remove();
        });
      document
        .querySelectorAll(".btn_msgReSet")[0]
        .addEventListener("click", function () {
          let btnThis = this;
          let formdata = new FormData();
          formdata.append("id", teamworkId);
          formdata.append("message", btnThis.getAttribute("data-reMsg"));
          fetch(`https://api.olapse.fifteenh.io/api/teamwork/send`, {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              document.querySelectorAll("#msgSender")[0].value = "";
              if (data.success == true) {
                btnThis.parentNode.parentNode.parentNode.parentNode.remove();
              } else {
                createPopup({
                  type: "confirm",
                  title: "전송 실패",
                  content: data.alert,
                });
              }
            });
        });
      document
        .querySelectorAll(".btn_msgDelSet")[0]
        .classList.remove("btn_msgDelSet");
      document
        .querySelectorAll(".btn_msgReSet")[0]
        .classList.remove("btn_msgReSet");
      scrollBottom();
    } else {
      const htmlString = `<div class="chat_row chat_row_prev"> <div class="chat_row_wrapper_my"> <p>${msg}</p> <div class="chat_info_right"> <span class="un_read"></span> <p class="time"></p> </div> </div> </div>`;
      document
        .querySelectorAll(".chat_data_inner")[0]
        .insertAdjacentHTML("beforeend", htmlString);
      scrollBottom();

      let formdata = new FormData();
      formdata.append("id", teamworkId);
      formdata.append("message", msg);
      if (document.querySelectorAll(".response_prev_ui")[0]) {
        formdata.append(
          "parent_id",
          document.querySelectorAll(".response_prev_ui")[0].getAttribute("idx")
        );
      }
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/send`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success !== true) {
            createPopup({
              type: "confirm",
              title: "전송 실패",
              content: data.alert,
            });
            const htmlString = `<div class="chat_row chat_row_prev chat_notSend"> <div class="chat_row_wrapper_my"> <p>${msg}</p> <div class="chat_info_right"> <div class="notMsgBox"><button type="button" class="btn_msgDel btn_msgDelSet"><img src="/assets/images/msgDel.svg"></button><button type="button" data-reMsg="${msg}" class="btn_msgRe btn_msgReSet"><img src="/assets/images/msgRe.svg"></button></div> </div> </div> </div>`;
            document
              .querySelectorAll(".chat_data_inner")[0]
              .insertAdjacentHTML("beforeend", htmlString);

            document
              .querySelectorAll(".btn_msgDelSet")[0]
              .addEventListener("click", function () {
                this.parentNode.parentNode.parentNode.parentNode.remove();
              });
            document
              .querySelectorAll(".btn_msgReSet")[0]
              .addEventListener("click", function () {
                let btnThis = this;
                let formdata = new FormData();
                formdata.append("id", teamworkId);
                formdata.append("message", btnThis.getAttribute("data-reMsg"));
                fetch(`https://api.olapse.fifteenh.io/api/teamwork/send`, {
                  method: "POST",
                  body: formdata,
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    document.querySelectorAll("#msgSender")[0].value = "";
                    if (data.success == true) {
                      btnThis.parentNode.parentNode.parentNode.parentNode.remove();
                    } else {
                      createPopup({
                        type: "confirm",
                        title: "전송 실패",
                        content: data.alert,
                      });
                    }
                  });
              });
            document
              .querySelectorAll(".btn_msgDelSet")[0]
              .classList.remove("btn_msgDelSet");
            document
              .querySelectorAll(".btn_msgReSet")[0]
              .classList.remove("btn_msgReSet");
            scrollBottom();
          }
        });
    }
    document.querySelectorAll("#msgSender")[0].value = "";
    document.querySelectorAll(".response_prev_ui")[0] &&
      document.querySelectorAll(".response_prev_ui")[0].remove();
  } else {
    document.querySelectorAll("#msgSender")[0].blur();
  }
}

function msgLock() {
  clearInterval(startStackTimer);
  startStackTimer = setInterval(function () {
    lockStack = 0;
  }, 500);
  if (lockStack > 6) {
    document.querySelectorAll("#msgSender")[0].value = "";
    document.querySelectorAll("#msgSender")[0].disabled = true;
    createPopup({
      type: "custom",
      title: "전송 실패",
      content:
        "너무 짧은 시간에 많은 메세지를 보냇습니다. <br> 잠시 후 다시 시도해주세요.",
      custom: () => {
        document.querySelectorAll("#msgSender")[0].disabled = false;
      },
    });
    lockStack = 0;
  } else {
    lockStack++;
  }
}

function myCalFunctionV2(teamworkUserId) {
  document.querySelectorAll(".d_11 .member_name")[0].textContent =
    document.querySelectorAll(".d_5 .member_name")[0].textContent +
    " 매출/정산";
  document
    .querySelectorAll(".d_11 .member_name")[0]
    .setAttribute("idx", teamworkId);
  document
    .querySelectorAll(".d_11 .member_name")[0]
    .setAttribute(
      "name",
      document.querySelectorAll(".d_5 .member_name")[0].textContent
    );
  if (myCalFunctionParams2.teamworkUserId == null) {
    myCalFunctionParams2.teamworkUserId = teamworkUserId;
  }

  document
    .querySelectorAll(
      "div.memeber_price_wrapper div.memeber_price_list_wrapper ul li"
    )
    .forEach((item, idx) => {
      item.remove();
    });

  fetch(
    `https://api.olapse.fifteenh.io/api/teamwork/member/calculate?id=${teamworkUserId}&begin_date=${myCalFunctionParams2.startDate}&end_date=${myCalFunctionParams2.endDate}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      var stDate = new Date(myCalFunctionParams2.startDate.replace(/-/g, "/"));
      var enDate = new Date(myCalFunctionParams2.endDate.replace(/-/g, "/"));
      var setDay =
        (enDate.getTime() - stDate.getTime()) / (1000 * 60 * 60 * 24);

      document.querySelectorAll(
        ".memeber_price_wrapper .date_picker_row span"
      )[0].textContent =
        myCalFunctionParams2.startDate +
        " ~ " +
        myCalFunctionParams2.endDate +
        " (" +
        setDay +
        "일)";
      document.querySelectorAll(
        "div.memeber_price_wrapper div.price_detail_info > h1"
      )[0].innerHTML = `<span>${
        data.data.schedules.length
      }건</span><span class="blue">${numberWithCommas(
        data.data.total_price
      )}원</span>`;
      if (data.data.schedules.length !== 0) {
        data.data.schedules.forEach((item, idx) => {
          const htmlString = `<li class="new_schedule_share_item"><div class="left"><h1>${numberWithCommas(
            item.price
          )}원 (카드)</h1><span>일정완료일 : ${
            item.completed_at ? item.completed_at.split(" ")[0] : "-"
          }</span></div><div class="memeber_price_list_right"><p class="${
            item.calculate_status == 1
              ? "type_2"
              : "type_1 complete_schedule_button_s"
          }">${item.calculate_status == 1 ? "완료" : "미완료"}</p></div></li>`;
          document
            .querySelectorAll(
              "div.memeber_price_wrapper div.memeber_price_list_wrapper ul"
            )[0]
            .insertAdjacentHTML("beforeend", htmlString);
          if (document.querySelectorAll(".complete_schedule_button_s")[0]) {
            document
              .querySelectorAll(".complete_schedule_button_s")[0]
              .addEventListener("click", function () {
                completeSchedule(item.id);
              });
            document
              .querySelectorAll(".complete_schedule_button_s")[0]
              .classList.remove("complete_schedule_button_s");
          }
          document
            .querySelectorAll(".new_schedule_share_item")[0]
            .addEventListener("click", function (e) {
              if (e.target.tagName !== "P") {
                window.location.href = `/more/payment_detail_2?idx=${item.id}`;
              }
            });
          document
            .querySelectorAll(".new_schedule_share_item")[0]
            .classList.remove("new_schedule_share_item");
        });
        document.querySelectorAll(
          ".memeber_price_wrapper .no_data"
        )[0].style.display = "none";
      } else {
        document.querySelectorAll(
          ".memeber_price_wrapper .no_data"
        )[0].style.display = "flex";
      }
    });
}

function myCalFunctionMy(teamworkUserId) {
  document.querySelectorAll(".d_11 .member_name")[0].textContent =
    "내 매출/정산";
  document
    .querySelectorAll(".d_11 .member_name")[0]
    .setAttribute("idx", teamworkMyId);
  document.querySelectorAll(".d_11 .member_name")[0].setAttribute("name", "내");
  if (myCalFunctionParams2.teamworkUserId == null) {
    myCalFunctionParams2.teamworkUserId = teamworkUserId;
  }

  document
    .querySelectorAll(
      "div.memeber_price_wrapper div.memeber_price_list_wrapper ul li"
    )
    .forEach((item, idx) => {
      item.remove();
    });

  fetch(
    `https://api.olapse.fifteenh.io/api/teamwork/member/calculate?id=${teamworkUserId}&begin_date=${myCalFunctionParams2.startDate}&end_date=${myCalFunctionParams2.endDate}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      var stDate = new Date(myCalFunctionParams2.startDate.replace(/-/g, "/"));
      var enDate = new Date(myCalFunctionParams2.endDate.replace(/-/g, "/"));
      var setDay =
        (enDate.getTime() - stDate.getTime()) / (1000 * 60 * 60 * 24);

      document.querySelectorAll(
        ".memeber_price_wrapper .date_picker_row span"
      )[0].textContent =
        myCalFunctionParams2.startDate +
        " ~ " +
        myCalFunctionParams2.endDate +
        " (" +
        setDay +
        "일)";
      document.querySelectorAll(
        "div.memeber_price_wrapper div.price_detail_info > h1"
      )[0].innerHTML = `<span>${
        data.data.schedules.length
      }건</span><span class="blue">${numberWithCommas(
        data.data.total_price
      )}원</span>`;
      if (data.data.schedules.length !== 0) {
        data.data.schedules.forEach((item, idx) => {
          const htmlString = `<li class="new_schedule_share_item"><div class="left"><h1>${numberWithCommas(
            item.price
          )}원 (카드)</h1><span>일정완료일 : ${
            item.completed_at ? item.completed_at.split(" ")[0] : "-"
          }</span></div><div class="memeber_price_list_right"><p class="${
            item.calculate_status == 1
              ? "type_2"
              : "type_1 complete_schedule_button_s"
          }">${item.calculate_status == 1 ? "완료" : "미완료"}</p></div></li>`;
          document
            .querySelectorAll(
              "div.memeber_price_wrapper div.memeber_price_list_wrapper ul"
            )[0]
            .insertAdjacentHTML("beforeend", htmlString);
          if (document.querySelectorAll(".complete_schedule_button_s")[0]) {
            document
              .querySelectorAll(".complete_schedule_button_s")[0]
              .addEventListener("click", function () {
                completeSchedule(item.id);
              });
            document
              .querySelectorAll(".complete_schedule_button_s")[0]
              .classList.remove("complete_schedule_button_s");
          }
          document
            .querySelectorAll(".new_schedule_share_item")[0]
            .addEventListener("click", function (e) {
              if (e.target.tagName !== "P") {
                window.location.href = `/more/payment_detail_2?idx=${item.id}`;
              }
            });
          document
            .querySelectorAll(".new_schedule_share_item")[0]
            .classList.remove("new_schedule_share_item");
        });
        document.querySelectorAll(
          ".memeber_price_wrapper .no_data"
        )[0].style.display = "none";
      } else {
        document.querySelectorAll(
          ".memeber_price_wrapper .no_data"
        )[0].style.display = "flex";
      }
    });
}
//멤버 매출 보기
function showMemeberPayment() {
  if (
    document.querySelectorAll(
      "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
    )[1]
  ) {
    document
      .querySelectorAll(
        "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
      )[1]
      .addEventListener("click", function () {
        _animateRightModule(true, document.querySelectorAll(".d_11")[0]);
        myCalFunctionV2(
          document.querySelectorAll(".d_5 .member_name")[0].getAttribute("idx")
        );
      });
  }
  if (document.querySelectorAll(".btnMyCal")[0]) {
    document
      .querySelectorAll(".btnMyCal")[0]
      .addEventListener("click", function () {
        myCalFunctionMy(teamworkMyId);
      });
  }
}

function completeSchedule(scheduleId) {
  let formdata = new FormData();
  let scheduleIdArr = [];
  scheduleIdArr.push(scheduleId);
  formdata.append("schedule_id[]", scheduleIdArr);
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/calculate/complete`, {
    method: "POST",
    body: formdata,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      if (data.success == true) {
        createPopup({
          type: "custom",
          title: "정산 완료",
          content: "정산이 완료되었습니다.",
          custom: () => {
            window.location.reload(true);
          },
        });
      } else {
        createPopup({
          type: "reTry",
          title: "정산 실패",
          content: data.alert,
          custom: () => {
            window.location.reload(true);
          },
        });
      }
    });
}

//멤버 상세
const memberDetailFunction = (trigger, teamworkUserId, userId, originData) => {
  trigger?.addEventListener("click", function () {
    if (
      document
        .querySelectorAll(".toggle_button_3")[0]
        .classList.contains("active")
    ) {
      if (myPermissionInfo == "팀장" || myPermissionInfo == "부팀장") {
        detailInner();
      } else {
        createPopup({
          type: "confirm",
          title: "조회 불가",
          content: "멤버간 프로필 보기가 제한된 팀워크입니다.",
        });
      }
    } else {
      detailInner();
    }
    function detailInner() {
      _animateRightModule(true, document.querySelectorAll(".d_5")[0]);
      getMemberInfo(teamworkUserId);
      const permissionButtonEl =
        document.querySelectorAll(".remove_permission")[0];

      if (myPermissionInfo == "팀장") {
        if (originData.is_leader == 0 && originData.is_sub_leader == 0) {
          //일반 팀원일 경우
          permissionButtonEl.style.display = "flex";
          permissionButtonEl.textContent = "부팀장 권한 부여";
        } else if (originData.is_sub_leader == 1) {
          //부팀장일 경우
          permissionButtonEl.style.display = "flex";
          permissionButtonEl.textContent = "부팀장 권한 제거";
        }
        permissionButtonEl.dataset.memberId = teamworkUserId;

        permissionButtonEl.addEventListener("click", function () {
          let formdata = new FormData();

          formdata.append("teamwork_user_id", this.dataset.memberId);
          fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/sub/leader`, {
            method: "POST",
            body: formdata,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              if (data.success == true) {
                if (data.data.type == 0) {
                  createPopup({
                    type: "custom",
                    title: "권한 제거 성공",
                    content: "부팀장 권한을 제거하였습니다.",
                    custom: () => {
                      window.location.reload(true);
                    },
                  });
                } else if (data.data.type == 1) {
                  createPopup({
                    type: "custom",
                    title: "권한 부여 성공",
                    content: "부팀장 권한을 부여하였습니다.",
                    custom: () => {
                      window.location.reload(true);
                    },
                  });
                }
              } else {
                createPopup({
                  type: "reTry",
                  title: "권한 설정 실패",
                  content: data.alert,
                });
              }
            });
        });
      } else {
        permissionButtonEl.style.display = "none";
      }

      fetch(`https://api.olapse.fifteenh.io/api/member/detail?user_id=${userId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          document.querySelectorAll(
            "div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call span"
          )[0].textContent = data.data.user.phone;
        });
    }
  });
  function getCalList(date, id, userId) {
    let dateArr = date.split("-"),
      startMonth = dateArr[0] + "-" + dateArr[1];

    document.querySelectorAll(".d_10 .calender_loading")[0].style.display =
      "flex";

    if (calendar !== undefined) {
      calendar.destroy();
    }

    document.querySelectorAll(".d_10 div.cal_guide h1 span")[0].textContent =
      getToday();
    // fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=${id}`, {
    fetch(
      `https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=${id}&month=${startMonth}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const calendarEl = document.querySelectorAll(".d_10 .calendar")[0];

        const scheduleData = data.data.schedules;
        if (scheduleData.length == 0) {
          document.querySelectorAll(
            ".d_10 .calender_loading"
          )[0].style.display = "none";
        }

        const events = scheduleData.map((item, idx) => {
          let className;
          if (item.status == 0) {
            className = "status_1";
          } else if (item.status == 1) {
            className = "status_2";
          } else if (item.status == 2) {
            className = "status_3";
          } else if (item.status == 5) {
            className = "status_4";
          } else if (item.status == 10) {
            className = "status_5";
          }

          if (scheduleData.length - 1 == idx) {
            document.querySelectorAll(
              ".d_10 .calender_loading"
            )[0].style.display = "none";
          }

          return {
            id: idx + 1,
            start: item.begin_date,
            className: className,
          };
        });

        let option = {
          plugins: [dayGridPlugin, listPlugin, interactionPlugin],
          initialView: "dayGridMonth",
          dayMaxEvents: 3,
          events: events,
          initialDate: new Date(date.replace(/-/g, "/")),
          // datesSet: function (info) {
          //     if(dateSetType == true){
          //         let newMonthArr = document.querySelectorAll(".d_10 .fc-toolbar-title")[0].textContent.replace("년 ","-").replace("월","").split("-"),
          //             newMonthArrY = newMonthArr[0],
          //             newMonthArrM = newMonthArr[1] >= 10 ? newMonthArr[1] : "0" + newMonthArr[1],
          //             newMonth = newMonthArrY + "-" + newMonthArrM + "-" + "01";

          //             dateSetType = false;
          //             getCalList(newMonth, id, userId);
          //     }
          // },
          dateClick: function (info) {
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active span"
            )[0].textContent = info.dateStr;
            document.querySelectorAll(
              ".d_10 div.cal_guide h1 span"
            )[0].textContent = info.dateStr;
            if (document.querySelectorAll(".fc_actived_day")[0]) {
              document
                .querySelectorAll(".fc_actived_day")
                .forEach((item, idx) => {
                  item.classList.remove("fc_actived_day");
                });
            }
            info.dayEl.querySelectorAll("a")[0].classList.add("fc_actived_day");
            date = info.dateStr;
            excute(document.querySelectorAll(".d_10 .data_list")[0]);
          },
          moreLinkContent: function (args) {
            return "+" + args.num;
          },
        };

        calendar = new FullCalendar.Calendar(calendarEl, option);
        calendar.setOption("locale", "ko");
        calendar.render();
        resetOption();
        calendarEl
          .querySelector(".d_10 .fc .fc-scroller-liquid-absolute")
          .classList.add("for_safari");

        function resetOption() {
          calendar.addEventSource({ ...option, events: events });

          const eventLength = scheduleData.length;
          for (let index = 0; index < eventLength + 1; index++) {
            calendar.getEventById(index)?.remove();
          }
          document
            .querySelectorAll(
              `.d_10 .fc-daygrid-day-number[aria-label="${dateArr[0]}년 ${
                dateArr[1] < 10 ? dateArr[1].replace("0", "") : dateArr[1]
              }월 ${
                dateArr[2] < 10 ? dateArr[2].replace("0", "") : dateArr[2]
              }일"]`
            )[0]
            .classList.add("fc_actived_day");
        }

        handleSafariFreezing();
        function handleSafariFreezing() {
          const prevButton = calendarEl.querySelector(".fc-prev-button"),
            nextButton = calendarEl.querySelector(".fc-next-button"),
            closeButton = calendarEl.previousElementSibling.firstElementChild;

          function resetCss(eventTarget, type) {
            eventTarget.addEventListener("mouseup", (e) => {
              if (dateSetType == true) {
                let newMonthArr = document
                    .querySelectorAll(".d_10 .fc-toolbar-title")[0]
                    .textContent.replace("년 ", "-")
                    .replace("월", "")
                    .split("-"),
                  chkDate = new Date(
                    newMonthArr[0] + "/" + newMonthArr[1] + "/" + 1
                  ),
                  typeChk =
                    type == "prev"
                      ? chkDate.getMonth() - 1
                      : type == "next"
                      ? chkDate.getMonth() + 1
                      : chkDate.getMonth();
                chkDate.setMonth(typeChk + 1);
                let newMonthArrY = chkDate.getFullYear(),
                  newMonthArrM =
                    chkDate.getMonth() >= 10
                      ? chkDate.getMonth()
                      : "0" + chkDate.getMonth(),
                  newMonth = newMonthArrY + "-" + newMonthArrM + "-" + "01";

                dateSetType = false;
                getCalList(newMonth, id, userId);

                document.querySelectorAll(
                  "div.popup_wrapper_2 div.date_picker_box div.time_col div.col.active span"
                )[0].textContent = newMonth;
                document.querySelectorAll(
                  ".d_10 div.cal_guide h1 span"
                )[0].textContent = newMonth;
                date = newMonth;
              }
            });
          }

          resetCss(prevButton, "prev");
          resetCss(nextButton, "next");
          resetCss(closeButton);
        }
        dateSetType = true;
      });

    document.querySelectorAll(".date_picker_container").forEach((item, idx) => {
      item.addEventListener("click", function () {
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "flex";
        calendar.render();
      });
    });

    document
      .querySelectorAll(".hide_date_popup")[0]
      .addEventListener("click", function () {
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "none";
      });
    document
      .querySelectorAll(".submit_date_popup")[0]
      .addEventListener("click", function () {
        if (
          document.querySelectorAll(
            "div.popup_wrapper_2 div.date_picker_box div.toggle_row div.toggle_button.active"
          )[0]
        ) {
          document.querySelectorAll(".date_picker_container")[0].textContent =
            document.querySelectorAll(".start_time_data")[0].textContent +
            " " +
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
            )[0].value;
          document.querySelectorAll(".date_picker_container")[1].textContent =
            document.querySelectorAll(".end_time_data")[0].textContent +
            " " +
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
            )[1].value;
          document.querySelectorAll(".date_picker_container")[1].style.display =
            "flex";
        } else {
          document.querySelectorAll(".date_picker_container")[0].textContent =
            document.querySelectorAll(".start_time_data")[0].textContent +
            " " +
            document.querySelectorAll(
              "div.popup_wrapper_2 div.date_picker_box div.time_col div.col2 input"
            )[0].value;
          document.querySelectorAll(".date_picker_container")[1].style.display =
            "none";
        }
        document.querySelectorAll(".popup_wrapper_2")[0].style.display = "none";
      });

    function excute(appendTarget) {
      fetch(
        `https://api.olapse.fifteenh.io/api/teamwork/member/schedule?id=${id}&date=${date}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          appendTarget.querySelectorAll("li").forEach((item, idx) => {
            item.remove();
          });
          if (data.success == true) {
            if (data.data.schedules.length !== 0) {
              document.querySelectorAll(".d_10 .no_data")[0].style.display =
                "none";
              data.data.schedules.forEach((item, idx) => {
                let className, title;
                if (item.status == 0) {
                  (className = "type_1"), (title = "요청");
                } else if (item.status == 1) {
                  (className = "type_2"), (title = `수락 / ${item.name}`);
                } else if (item.status == 2) {
                  (className = "type_3"), (title = `완료 / ${item.name}`);
                } else if (item.status == 5) {
                  (className = "type_4"), (title = `보류 / ${item.name}`);
                } else if (item.status == 10) {
                  (className = "type_5"), (title = `취소 / ${item.name}`);
                }
                const htmlString = `<li idx="${
                  item.id
                }" class="new_cal_item active" status="${
                  item.status
                }"> <p class="${className}"></p> <div class="data_list_textArea"> <h1>${title}</h1> <span>${
                  item.contents
                }</span> <h1> <span class="date_created_at">${
                  item.created_at
                }</span> <span class="data_price_detail">${numberWithCommas(
                  item.price
                )}원</span> </h1> </div> </li>`;
                appendTarget.insertAdjacentHTML("beforeend", htmlString);
                document
                  .querySelectorAll(".new_cal_item")[0]
                  .addEventListener("click", function () {
                    document
                      .querySelectorAll(".right_popup")
                      .forEach((item, idx) => {
                        _animateRightModule(false, item);
                      });
                    setTimeout(() => {
                      _animateRightModule(
                        true,
                        document.querySelectorAll(".d_4")[0]
                      );
                      updateScheduleData(item.id, myPermissionInfo);
                    }, 500);
                    document
                      .querySelectorAll(".common_more_wrapper li")
                      .forEach((item2, idx2) => {
                        item2.setAttribute("data-schedule_id", item.id);
                      });
                    document
                      .querySelectorAll(".edit_schedule")[0]
                      .setAttribute("data-schedule_id", item.id);
                  });
                document
                  .querySelectorAll(".new_cal_item")[0]
                  .classList.remove("new_cal_item");
              });
            } else {
              document.querySelectorAll(".d_10 .no_data")[0].style.display =
                "flex";
            }

            if (data.data.schedules.next_page_url) {
              inner(data.data.schedules.next_page_url + pram);
            } else {
              if (
                !document.querySelectorAll(
                  "div.data_wrapper > ul.data_list > li.active"
                )[0]
              ) {
              }
            }
          } else {
            createPopup({
              type: "confirm",
              title: "조회 실패",
              content: data.alert,
            });
          }
        });
    }
    document
      .querySelectorAll("div.data_wrapper > ul.data_list > li")
      .forEach((item, idx) => {
        item.remove();
      });
    excute(document.querySelectorAll(".d_10 .data_list")[0]);
  }

  function getMemberInfo(id) {
    fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/detail?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        teamworkId = data.data.teamwork_user.id;
        document.querySelectorAll(".d_5 .member_name")[0].textContent =
          data.data.teamwork_user.name;
        document
          .querySelectorAll(".d_5 .member_name")[0]
          .setAttribute("member_id", data.data.teamwork_user.user_id);
        document
          .querySelectorAll(".d_5 .member_name")[0]
          .setAttribute("idx", data.data.teamwork_user.id);
        document.querySelectorAll(".d_10 .member_name")[0].textContent =
          data.data.teamwork_user.name;
        //친구 등록이 되어있지 않은 경우
        document.querySelectorAll(".chat_add_friend")[0].style.display = "none";
        document.querySelectorAll(
          "div.memeber_detail_wrapper ul.member_detail_menu_list"
        )[0].style.display = "flex";
        if (data.data.teamwork_user.is_friend == null) {
          if (myId == userId) {
            document.querySelectorAll(".chat_add_friend")[0].style.display =
              "none";
          } else {
            document.querySelectorAll(".chat_add_friend")[0].style.display =
              "flex";
          }

          document.querySelectorAll(
            "div.memeber_detail_wrapper ul.member_detail_menu_list"
          )[0].style.display = "none";
        }
        if (data.data.teamwork_user.is_sub_leader == 0) {
          document.querySelectorAll(".d_5 .remove_permission")[0].textContent =
            "부팀장 권한 부여";
        } else {
          document.querySelectorAll(".d_5 .remove_permission")[0].textContent =
            "부팀장 권한 제거";
        }

        if (myPermissionInfo == "팀장") {
          if (myId == userId) {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "none";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "none";
          } else if (data.data.teamwork_user.is_leader == 1) {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "flex";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "none";
          } else {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "flex";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "flex";
          }
        }

        if (myPermissionInfo == "부팀장") {
          if (myId == userId) {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "none";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "none";
          } else if (
            data.data.teamwork_user.is_leader == 1 ||
            data.data.teamwork_user.is_sub_leader == 1
          ) {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "none";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "none";
          } else {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "flex";
            document.querySelectorAll(
              ".d_5 .remove_permission"
            )[0].style.display = "none";
          }
        }

        if (myPermissionInfo == "팀원") {
          if (myId == userId) {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[0].style.display = "flex";
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[1].style.display = "flex";
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "none";
          } else {
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[0].style.display = "none";
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[1].style.display = "none";
            document.querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
            )[2].style.display = "none";
          }
        }
        document
          .querySelectorAll(
            "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
          )[0]
          .addEventListener("click", function () {
            _animateRightModule(true, document.querySelectorAll(".d_10")[0]);
            getCalList(getToday(), teamworkId, data.data.teamwork_user.user_id);
            dateSetType = false;
          });

        document
          .querySelectorAll(
            "div.memeber_detail_wrapper div.member_detail_menu_list_wrapper ul li"
          )[2]
          .addEventListener("click", function () {
            createPopup({
              type: "custom_select",
              title: "멤버 내보내기",
              content: "선택한 멤버를 내보내시겠습니까?",
              custom: () => {
                let formdata = new FormData();
                formdata.append("teamwork_id", getParameterByName("idx"));
                formdata.append("teamwork_user_ids[]", teamworkId);
                fetch(`https://api.olapse.fifteenh.io/api/teamwork/member/out`, {
                  method: "POST",
                  body: formdata,
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                  .then(function (response) {
                    return response.json();
                  })
                  .then(function (data) {
                    if (data.success == true) {
                      window.location.reload(true);
                    } else {
                      createPopup({
                        type: "reTry",
                        title: "내보내기 실패",
                        content: data.alert,
                      });
                    }
                  });
              },
            });
          });
        document.querySelectorAll(
          "div.chat_wrapper.white_m_wrapper div.white_m_header span"
        )[0].textContent = data.data.teamwork_user.name;
        document.querySelectorAll(
          "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile"
        )[0].style.background = `url(${
          data.data.teamwork_user.profile_url
            ? data.data.teamwork_user.profile_url
            : "/assets/images/icon_profile_basic.svg"
        }) no-repeat`;
        document.querySelectorAll(
          "div.memeber_detail_wrapper ul.member_detail_menu_list li.member_call span"
        )[0];
        document
          .querySelectorAll(".create_new_teamwork")[0]
          .setAttribute("idx", data.data.teamwork_user.user_id);
        document
          .querySelectorAll(".create_new_teamwork")[0]
          .addEventListener("click", function () {
            window.location.href = `create?selected_id=${data.data.teamwork_user.user_id}`;
          });
        userId = data.data.teamwork_user.user_id;
        if (data.data.teamwork_user.is_leader == 1) {
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.add("mark_type_2");
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.remove("mark_type_1");
        } else if (data.data.teamwork_user.is_sub_leader == 1) {
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.add("mark_type_1");
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.remove("mark_type_2");
        } else if (
          data.data.teamwork_user.is_leader == 0 &&
          data.data.teamwork_user.is_sub_leader == 0
        ) {
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.remove("mark_type_1");
          document
            .querySelectorAll(
              "div.memeber_detail_wrapper div.member_detail_info_top div.member_detail_profile p"
            )[0]
            .classList.remove("mark_type_2");
        }
      });
  }
};

function dateSecondCutter(date) {
  return date.split(":")[0] + ":" + date.split(":")[1];
}

let mychtId = "";
/*fetch(`https://api.olapse.fifteenh.io/api/teamwork`, {
  method: "GET",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
})
  .then(function (response) {
    return response.json();
  })
  .then(function (data) {
    data?.data?.teamworks?.forEach((item, idx) => {
      if (item.title == "나와의 채팅") {
        mychtId = item.id;
        return false;
      }
    });
  });*/

//일정 상세보기 정보 업데이트
const updateScheduleData = (id, myPermissionInfo) => {
  fetch(`https://api.olapse.fifteenh.io/api/teamwork/schedule/detail?id=${id}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      let titleHtmlString;
      const buttonWrapper = document.querySelectorAll(
        ".date_detail_button_wrapper"
      )[0];
      function clearButtonInner() {
        if (buttonWrapper.querySelectorAll("li")[0]) {
          buttonWrapper.querySelectorAll("li").forEach((item, idx) => {
            item.remove();
          });
        }
      }

      document.querySelectorAll(".accept_request_row")[0].style.display =
          "block";
      document.querySelectorAll(".accept_request_row")[1].style.display =
          "flex";
      document.querySelectorAll(".player_row_inner")[0].textContent =
          data.data.schedule.request_user_name;

      if (data.data.schedule.status === 0 || data.data.schedule.status === 1) {
        //요청
        // 일정 수정 : 팀장 / 부팀장만 노출(수정 시 [수정됨] 표시 필요)
        // 고객 연락처 : 팀장 / 부팀장만 노출
        // 더보기 : 팀장 / 부팀장만 노출(일정 공유, 일정 삭제)
        titleHtmlString = `접수 <p class="status_2"></p>`;

        buttonWrapper.insertAdjacentHTML(
            "beforeend",
            `<li class="new_check_schedules">일정완료</li>`
        );

        document
            .querySelectorAll(".new_check_schedules")[0]
            .addEventListener("click", function () {
              document.querySelectorAll(
                  ".payment_select_popup_wrapper"
              )[0].style.display = "flex";
              _animationAppearTopDown(
                  document.querySelectorAll(
                      ".payment_select_popup_wrapper .payment_select_popup_box"
                  )[0]
              );
              document
                  .querySelectorAll(
                      ".payment_select_popup_box_button_wrapper button:nth-of-type(2)"
                  )[0]
                  .setAttribute("schedule_index", data.data.schedule.id);
            });
      } else if (data.data.schedule.status == 2) {
        //완료
        //일정 상세 (완료)
        // 일정 수정 : 버튼 미노출
        // 고객 연락처 : 팀장 / 부팀장 / 수락자만 노출
        // 더보기 : 버튼 미노출
        titleHtmlString = `완료 <p class="status_3"></p>`;
      } else if (data.data.schedule.status == 5) {
        titleHtmlString = `보류 <p class="status_5"></p>`;

        buttonWrapper.insertAdjacentHTML(
            "beforeend",
            `<li class="new_check_schedules">일정완료</li>`
        );

        document
            .querySelectorAll(".new_check_schedules")[0]
            .addEventListener("click", function () {
              document.querySelectorAll(
                  ".payment_select_popup_wrapper"
              )[0].style.display = "flex";
              _animationAppearTopDown(
                  document.querySelectorAll(
                      ".payment_select_popup_wrapper .payment_select_popup_box"
                  )[0]
              );
              document
                  .querySelectorAll(
                      ".payment_select_popup_box_button_wrapper button:nth-of-type(2)"
                  )[0]
                  .setAttribute("schedule_index", data.data.schedule.id);
            });
      } else if (data.data.schedule.status == 10) {
        titleHtmlString = `취소 <p class="status_10"></p>`;
      }

      document.querySelectorAll("div.date_detail_3 h1")[0].innerHTML =
        titleHtmlString;

      let str = data.data.schedule.contents;
      let regExp = /^\D?\d{3}-?\d{3,4}-?\d{4}\D?$/;

      if (data.data.schedule.contents.indexOf("\n") >= 0) {
        for (const content of data.data.schedule.contents.split("\n")) {
          if (content.indexOf(" ") >= 0) {
            for (const con of content.split(" ")) {
              if (regExp.test(con.replace(/\r/g, ""))) {
                let el = `<span style="display: inline-block; text-decoration: underline; width: auto;">${con}</span>`;
                str = str.replace(con, el);
              }
            }
          } else {
            if (regExp.test(content.replace(/\r/g, ""))) {
              let el = `<span style="display: inline-block; text-decoration: underline; width: auto;">${content}</span>`;
              str = str.replace(content, el);
            }
          }
        }
      } else {
        for (const con of data.data.schedule.contents.split(" ")) {
          if (regExp.test(con.replace(/\r/g, ""))) {
            let el = `<span style="display: inline-block; text-decoration: underline; width: auto;">${con}</span>`;
            str = str.replace(con, el);
          }
        }
      }

      document.querySelectorAll(
        "div.date_detail_3 div.player_row div.date_detail_textArea span"
      )[0].innerHTML = str;
      document
        .querySelectorAll(
          "div.date_detail_3 div.player_row div.date_detail_textArea span span"
        )
        .forEach((element) => {
          element.addEventListener("click", function () {
            phoneCall(element.textContent);
          });
        });

      document.querySelectorAll(
        "div.date_detail_3 div.player_row ul.player_row_2 li:nth-of-type(1) span"
      )[0].textContent = dateSecondCutter(data.data.schedule.begin_date);
      document.querySelectorAll(
        "div.date_detail_3 div.player_row ul.player_row_2 li:nth-of-type(2) span"
      )[0].textContent = data.data.schedule.end_date
        ? dateSecondCutter(data.data.schedule.end_date)
        : "-";
      document.querySelectorAll(
        "div.date_detail_3 div.player_row ul.player_row_3 span.blue"
      )[0].textContent = data.data.schedule.phone;

      document.querySelectorAll(
        "div.date_detail_3 div.player_row div.respect_price span"
      )[0].textContent = `${numberWithCommas(data.data.schedule.price)}원`;

      document.querySelectorAll(".d_9 #payment_name")[0].value =
        data.data.schedule.request_user_name;
      document.querySelectorAll(".d_9 #payment_phone")[0].value =
        data.data.schedule.phone;
      document.querySelectorAll(".d_9 #payment_price")[0].value =
        data.data.schedule.price;
      document.querySelector(".d_9 #payment_card_number").value = "";
      document.querySelector(".d_9 #payment_due_date").value = "";

      const fileAppendTarget = document.querySelectorAll(
        "div.date_detail_3 div.player_row div.file_add_section ul.b_submit_file_list"
      )[0];
      fileAppendTarget.querySelectorAll("li").forEach((item, idx) => {
        item.remove();
      });

      if (data.data.schedule_files.length !== 0) {
        data.data.schedule_files.forEach((item, idx) => {
          let htmlString;
          if (
            imgTypeArr.indexOf(
              item.file_url.substring(
                Number(item.file_url.lastIndexOf(".")) + 1,
                String(item.file_url).length
              )
            ) !== -1
          ) {
            htmlString = `<li style="background:url(${item.file_url}) no-repeat" class="added_file new_added_file"></li>`;
            fileAppendTarget.insertAdjacentHTML("beforeend", htmlString);

            imgViewerFunction(
              document.querySelectorAll(".new_added_file")[0],
              item.file_url,
              data.data.schedule.request_user_name,
              item
            );
          } else {
            htmlString = `<li class="added_file new_added_file"> <div class="file_icon"></div> <span class="file_name">${
              item.file_url.split("/")[item.file_url.split("/").length - 1]
            }</span> <span class="file_size">${
              item.file_size
            }kb</span> <a style="display:none;" href="${`https://api.olapse.fifteenh.io/api/file/download?src=${item.file_url}&file_type=image&file_extension=${item.file_extension}`}" download></a> </li>`;
            fileAppendTarget.insertAdjacentHTML("beforeend", htmlString);
            document
              .querySelectorAll(".new_added_file")[0]
              .addEventListener("click", function () {
                this.querySelectorAll("a")[0].click();
              });
          }

          document
            .querySelectorAll(".new_added_file")[0]
            .classList.remove("new_added_file");
        });
      }
    });
  function paymentExcute(id) {
    if (
      String(document.querySelector("#payment_name").value).length !== 0 &&
      String(document.querySelector("#payment_card_number").value).length !==
        0 &&
      String(document.querySelector("#payment_due_date").value).length !== 0
    ) {
      let formdata = new FormData();
      formdata.append("name", document.querySelector("#payment_name").value);
      formdata.append("price", document.querySelector("#payment_price").value);
      formdata.append(
        "card_num",
        document.querySelector("#payment_card_number").value
      );
      formdata.append(
        "card_expire",
        document.querySelector("#payment_due_date").value
      );
      formdata.append("schedule_id", id);
      formdata.append(
        "card_quota",
        document.querySelectorAll("#payment_month option:checked")[0].value
      );
      fetch(`https://api.olapse.fifteenh.io/api/schedule/payment`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "결제 완료",
              content: "결제가 완료되었습니다.",
              custom: () => {
                window.location.reload(true);
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "결제 실패",
              content: data.alert,
            });
          }
        });
    } else {
      createPopup({
        type: "reTry",
        title: "결제 실패",
        content: "모든 필수 정보를 입력해주세요.",
      });
    }
  }
  // document.querySelectorAll(".payment_submit_button")[0].addEventListener("click", function () {
  //     paymentExcute(this.getAttribute("schedule_index"));
  // });
};
//파일첨부, 공지사항 작성 기능
const toggleSubMenu = () => {
  const triggerElement = document.querySelectorAll(
      "div.chatting_area div.chat_write ul.left_button_list li.plus"
    )[0],
    menuElement = document.querySelectorAll(".chat_sub_write_menu")[0],
    chatElement = document.querySelectorAll(
      "div.chatting_area div.chat_write"
    )[0];
  triggerElement.addEventListener("click", function () {
    if (this.classList.contains("active")) {
      (menuElement.style.display = "none"),
        chatElement.classList.remove("active"),
        this.classList.remove("active");
    } else {
      (menuElement.style.display = "flex"),
        chatElement.classList.add("active"),
        this.classList.add("active");
    }
  });
};

//채팅 파일 업로드
const fileUploadChat = (teamworkId) => {
  const triggerInputElement = document.querySelectorAll(
    "div.chat_sub_write_menu ul li input"
  )[0];
  triggerInputElement.addEventListener("input", function (e) {
    if (e.target.files[0].type.split("/")[0] == "video") {
      const htmlString = `<div class="user_new_chat_row video_prev_row new_chat_file_row chat_file_row chat_file_row_right chatVideoLodingItem"> <span class="video_prev_alert">동영상을 로드중입니다.</span></div>`;
      document
        .querySelectorAll(".chat_data_inner")[0]
        .insertAdjacentHTML("beforeend", htmlString);
    }
    if (e.target.files[0].size < 524288000) {
      let formdata = new FormData();
      formdata.append("id", teamworkId);
      formdata.append("file", e.target.files[0], e.target.files[0].name);
      fetch(`https://api.olapse.fifteenh.io/api/teamwork/send`, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            const menuElement = document.querySelectorAll(
                ".chat_sub_write_menu"
              )[0],
              chatElement = document.querySelectorAll(
                "div.chatting_area div.chat_write"
              )[0];
            (menuElement.style.display = "none"),
              chatElement.classList.remove("active"),
              document
                .querySelectorAll(
                  "div.chatting_area div.chat_write ul.left_button_list li.plus"
                )[0]
                .classList.remove("active");
            document.querySelectorAll(".chatVideoLodingItem")[0] &&
              document
                .querySelectorAll(".chatVideoLodingItem")[0]
                .classList.remove("chatVideoLodingItem");
          } else {
            document.querySelectorAll(".chatVideoLodingItem")[0] &&
              document.querySelectorAll(".chatVideoLodingItem")[0].remove();
            createPopup({
              type: "reTry",
              title: "업로드 불가",
              content: data.alert,
            });
          }
        });
    } else {
      document.querySelectorAll(".chatVideoLodingItem")[0] &&
        document.querySelectorAll(".chatVideoLodingItem")[0].remove();
      createPopup({
        type: "reTry",
        title: "업로드 불가",
        content: "500mb이하의 파일만 전송 가능합니다.",
      });
    }
  });
};

export {
  readLastMsg,
  scrollBottom,
  sendMsgExcute,
  memberDetailFunction,
  updateScheduleData,
  toggleSubMenu,
  fileUploadChat,
  enterTeamwork,
  myPermission,
};
