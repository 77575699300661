// 2022-02-24 인수인계
// 기능 : 기본 GNB입니다.

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import initMyInfo from "../shared/myProfile";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import TeamChat from "./msgHandler";
import { fetcherWithToken, getParameterByName } from "../shared/script";

const MainMenu = (props) => {
  // const {data: member} = useSWR(["https://api.olapse.fifteenh.io/api/member", localStorage.getItem("token")], fetcherWithToken);
  // const {data: unread} = useSWR(["https://api.olapse.fifteenh.io/api/teamwork/unread/message", localStorage.getItem("token")], fetcherWithToken);
  const navigate = useNavigate();
  window.location.pathname !== "/chat" && TeamChat();

  useEffect(() => {
    fetch("https://api.olapse.fifteenh.io/api/member", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        if (response.status == 500) {
          localStorage.removeItem("page");
          localStorage.removeItem("accessTokenState");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("token");
          localStorage.removeItem("chat_with_me_id");
          localStorage.removeItem("tabs");
          localStorage.removeItem("refreshTokenState");
          localStorage.removeItem("end_subs");
          localStorage.removeItem("login_id");
          localStorage.removeItem("code");
          localStorage.removeItem("phone");
          localStorage.removeItem("name");
          createPopup({
            type: "custom",
            title: "세션 만료",
            content:
              "세션이 만료되었거나 회원탈퇴되었습니다.<br />재 로그인, 또는 재 가입 후 이용가능합니다.",
            custom: () => {
              window.location.href = "/login";
            },
          });
        }
        return response.json();
      })
      .then(function (data) {
        if (data.success == true) {
          let member = data;
          document
            .querySelectorAll("#userMenuProfile")[0]
            .setAttribute("idx", member?.data?.my_info?.id);
          document.querySelectorAll(
            "#userMenuProfile"
          )[0].style.background = `url(${
            member?.data?.my_info?.profile_url
              ? member?.data?.my_info?.profile_url
              : "/assets/images/icon_profile_basic.svg"
          })`;
        }
      });

    /*const echo = new Echo({
      host: "https://api.olapse.fifteenh.io:6006/",
      broadcaster: "socket.io",
      client: socketio,
    });*/
    let chkTime,
      endTime,
      startChk = false;

    let chkTimeCount = 0,
      endTimeCount = 0;

    function listReSet() {
      if (endTimeCount >= 2500 || chkTimeCount >= 1500) {
        chkTimeCount = 0;
        endTimeCount = 0;
        clearInterval(chkTime);
        clearInterval(endTime);
        startChk = false;
        TeamChat();
      }
    }

    let chatFSet = true;
    /*echo.channel("laravel_database_chat").listen("MessagePushed", (e) => {
      function pushSetting() {
        if (
          e.type !== "user_info_modify" &&
          e.type !== "member_invite" &&
          e.type !== "member_add" &&
          e.type !== "info_modify"
        ) {
          chkTimeCount = 0;
          if (!startChk) {
            (chkTimeCount = 0), (endTimeCount = 0);
            startChk = true;
            chkTime = setInterval(() => {
              chkTimeCount = chkTimeCount + 100;
              listReSet();
            }, 100);
            endTime = setInterval(() => {
              endTimeCount = endTimeCount + 100;
              listReSet();
            }, 100);
          }
        }
      }
      if (location.pathname == "/chat" && getParameterByName("idx") !== "") {
        if (chatFSet) {
          if (e.type == "read") {
            pushSetting();
            chatFSet = false;
          }
        } else {
          pushSetting();
        }
      } else {
        pushSetting();
      }
    });*/

    initMyInfo();
    document.querySelectorAll(".left_menu_bar ul li").forEach((item, idx) => {
      item.classList.remove("active");
    });
    const pathName = window.location.pathname;
    if (pathName.split("/")[1] === "main") {
      document.querySelectorAll(".member")[0].classList.add("active");
    } else if (pathName.split("/")[1] === "chat") {
      document.querySelectorAll(".chat")[0].classList.add("active");
    } else if (
      pathName.split("/")[1] === "cal" ||
      pathName.split("/")[1] === "cal_all"
    ) {
      document.querySelectorAll(".cal")[0].classList.add("active");
    } else if (pathName.split("/")[1] === "more") {
      document.querySelectorAll(".more")[0].classList.add("active");
    } else if (pathName == "/date_detail") {
      document.querySelectorAll(".cal")[0].classList.add("active");
    }
  }, []);

  return (
    <>
      <div className="left_menu_bar">
        <ul>
          <li id="userMenuProfile" className="user_profile">
            <p></p>
          </li>
          <li className="cal active">
            <a onClick={() => navigate("/cal")}>
              <img src="/assets/images/icon_cal.svg" alt="" />
            </a>
          </li>
          <li className="more">
            <a onClick={() => navigate("/more")}>
              <img src="/assets/images/icon_more.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MainMenu;
