// 2022-02-24 인수인계
// 기능 : 정보수정 페이지입니다.

import React, {useEffect, useState} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
import createPopup from "./createPopup";

const EditInfo = () => {
  const navigate = useNavigate();

  const [region, setRegion] = useState("");

  useEffect(() => {
    //기저장한 내 정보 불러옴
    fetch("https://api.olapse.fifteenh.io/api/mypage", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        document.querySelectorAll(
          "div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper input"
        )[0].value = data.data.name;
        document.querySelectorAll(
          "div.edit_wrapper div.profile_img_input_wrapper div.edit_info_wrapper select"
        )[0].value = data.data.region ? data.data.region : "";
        setRegion(data.data.region ? data.data.region : "")
      });
    //수정 실행
    const editProfile = () => {
      if (
        String(document.querySelector("#user_name").value).length !== 0 &&
        String(document.querySelector("#region").value).length !== 0
      ) {
        let formdata = new FormData();
        formdata.append("name", document.querySelector("#user_name").value);
        formdata.append("region", document.querySelector("#region").value);

        fetch(`https://api.olapse.fifteenh.io/api/mypage/modify`, {
          method: "POST",
          body: formdata,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            if (data.success == true) {
              createPopup({
                type: "custom",
                title: "수정완료",
                content: "회원정보가 수정되었습니다.",
                custom: () => {
                  window.location.reload(true);
                },
              });
            } else {
              createPopup({
                type: "reTry",
                title: "수정실패",
                content: data.alert,
              });
            }
          });
      } else {
        createPopup({
          type: "reTry",
          title: "수정실패",
          content: "모든 정보를 입력해주세요.",
        });
      }
    };
    document
      .querySelectorAll(".profile_edit_complete_button")[0]
      .addEventListener("click", function () {
        editProfile();
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="회원정보 수정" />
        <div className="edit_wrapper">
          <div className="profile_img_input_wrapper">
            <div className="edit_info_wrapper">
              <div className="row">
                <h1>이름</h1>
                <input
                  id="user_name"
                  onKeyUp={(e) => {
                    if (e.target.value.length > 20) {
                      e.target.value = e.target.value.substr(0, 20);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.target.value.length > 20) {
                      e.target.value = e.target.value.substr(0, 20);
                    }
                  }}
                  placeholder="이름을 입력하세요."
                  type="text"
                  maxLength="20"
                />
              </div>
              <div className="row input_wrapper">
                <h1>활동지역</h1>
                <div className="input_outter">
                  <select id="region" className={`custom-select ${region === "" ? 'placeholder' : ''}`} onChange={(e) => setRegion(e?.target?.value)}>
                    <option value="">활동지역 선택</option>
                    <option value="서울">서울</option>
                    <option value="경기">경기</option>
                  </select>
                </div>
              </div>
              <button className="profile_edit_complete_button">
                수정 완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditInfo;
