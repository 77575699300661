// 2022-02-24 인수인계
// 기능 : 더보기(/more) 페이지의 좌측 UI입니다.

import React, {useEffect} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import "../assets/css/skeleton_ui.css";
import "../assets/css/common.css";
import "../assets/css/login.css";
import "../assets/css/button.css";
import "../assets/css/popup.css";
import "../assets/css/gnb.css";
import "../assets/css/agree.css";
import "../assets/css/user.css";
import "../assets/css/input.css";
import "../assets/css/main.css";
import "../assets/css/more.css";
import "../assets/css/setting.css";
import "../assets/css/member.css";
import "../assets/css/search.css";
import "../assets/css/add.css";
import "../assets/css/profile.css";
import {getParameterByName, toggleClass, IsLogin, fetcherWithToken} from "../shared/script.js";
import createPopup from "../components/createPopup";
import {_animationAppearTopDown, _animationAppearLeft, _animationDisappearRight, _animationAppearRight, _animationDisappearLeft, _animationDisappearBottomUp, _animationDisappearBottomUpV2, _animationDisappearBottomUpV3, _popupAnimationAppearTopDown, _popupAnimationDisappearBottomUp, _agreePopupActive, _agreePopupOut, _controllerActive, _controllerDisappear, _animateRightModule} from "../components/animation.js";
import useSWR from "swr";

const MoreMenu = () => {
    const {data: mypageInfo, error} = useSWR(["https://api.olapse.fifteenh.io/api/mypage", localStorage.getItem("token")], fetcherWithToken);
    const navigate = useNavigate();
    const getNowTimeLine = () => {
        let now = new Date(); // 현재 날짜 및 시간
        let month = now.getMonth(); // 월
        return month + 1 + "월";
    };
    const logout = () => {
        fetch(`https://api.olapse.fifteenh.io/api/logout`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                localStorage.removeItem("token");
                localStorage.removeItem("refresh_token");
                navigate("/login");
            });
    };
    return (
        <>
            <div className="chat_wrapper">
                <div className="chat_header">
                    <h1>더보기</h1>
                </div>
                <div className="more_area">
                    <div onClick={() => navigate("/more/edit_info")} className="more_my_info">
                        <div className="more_my_info_col">
                            <h1>{mypageInfo?.data.name}</h1>
                            {mypageInfo?.data?.team_leader == null ? <span>소속 없음</span> : <span>{mypageInfo?.data?.team_leader} 팀장 소속</span>}
                        </div>
                    </div>
                    {mypageInfo?.data?.team_leader === null && (
                        <div className="data_save_wrapper">
                            <div className="none_data_save">
                                <span>소속하신 팀이 없습니다.<br/>팀장에게 팀코드를 전달받고 코드를 등록해보세요.</span>
                                <a onClick={() => navigate('/more/team/code')}>팀코드 등록</a>
                            </div>
                        </div>
                    )}
                    <ul className="account_setting_wrapper">
                        <li>
                            <a onClick={() => navigate("/more/submit_b")}>사업자 인증</a>
                        </li>
                        <li>
                            <a onClick={() => navigate("/more/schedule_list?isReq=0")}>일정내역</a>
                        </li>
                        <li>
                            <a onClick={() => {
                                createPopup({
                                    type: "custom_select_2",
                                    title: "계정 비밀번호 입력",
                                    content: "<input placeholder='계정 비밀번호 입력' id='accountPw' type='password'>",
                                    button_text_1: "취소",
                                    button_text_2: "확인",
                                    firstButtonClickFunction: () => {
                                        if (document.querySelectorAll("#accountPw")[0].value == "") {
                                            setTimeout(() => {
                                                createPopup({
                                                    type: "reTry",
                                                    title: "인증 실패",
                                                    content: "비밀번호를 입력해주세요.",
                                                });
                                            }, 350);
                                        } else {
                                            let formdata = new FormData();
                                            formdata.append("password", document.querySelectorAll("#accountPw")[0].value);
                                            fetch(`https://api.olapse.fifteenh.io/api/password/check`, {
                                                method: "POST",
                                                body: formdata,
                                                headers: {
                                                    Authorization: "Bearer " + localStorage.getItem("token"),
                                                },
                                            })
                                                .then(function (response) {
                                                    return response.json();
                                                })
                                                .then(function (data) {
                                                    if (data.success == true) {
                                                        navigate("/more/setting");
                                                    } else {
                                                        setTimeout(() => {
                                                            createPopup({
                                                                type: "reTry",
                                                                title: "인증 실패",
                                                                content: data.alert,
                                                            });
                                                        }, 350);
                                                    }
                                                });
                                        }
                                    },
                                    secondButtonClickFunction: () => {
                                    },
                                });
                            }}>계정 설정</a>
                        </li>
                        <li>
                            <a onClick={() => navigate("/more/notice")}>공지사항</a>
                        </li>
                        <li>
                            <a onClick={() => navigate("/more/customer_center/0")}>고객센터</a>
                        </li>
                    </ul>
                    <button onClick={() => logout()} className="logout_button">
                        로그아웃
                    </button>
                    <div className="sideMenuFooter">
                        <h2 className="sideMenuFooterTitle">오랩스</h2>
                        <p className="sideMenuFooterText">대표 : 이인구<br/>사업자 번호 : 785-86-02476<br/>개인정보 관리 책임자 : 이지만<br/>제휴문의 : hello@olapse.com</p>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MoreMenu;
