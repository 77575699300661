// 2022-02-24 인수인계
// 기능 : 결제 상세 페이지입니다.

import React, { useEffect } from "react";
import "../assets/css/main_2.css";
import { useNavigate } from "react-router-dom";
import {
  getParameterByName,
  getToday,
  lastWeek,
  noData,
  numberWithCommas,
} from "../shared/script.js";
import {
  readLastMsg,
  scrollBottom,
  sendMsgExcute,
  memberDetailFunction,
  updateScheduleData,
  toggleSubMenu,
  fileUploadChat,
  enterTeamwork,
} from "../shared/chat.js";
import {
  _animationAppearTopDown,
  _animationAppearLeft,
  _animationDisappearRight,
  _animationAppearRight,
  _animationDisappearLeft,
  _animationDisappearBottomUp,
  _animationDisappearBottomUpV2,
  _animationDisappearBottomUpV3,
  _popupAnimationAppearTopDown,
  _popupAnimationDisappearBottomUp,
  _agreePopupActive,
  _agreePopupOut,
  _controllerActive,
  _controllerDisappear,
  _animateRightModule,
} from "../components/animation.js";
import createPopup from "./createPopup";
import * as Calendar from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { filteringBtns } from "../shared/script.js";
import TeamworkModule from "./teamworkModules";

const PaymentDetail2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let url,
      cancelUrl = "https://api.olapse.fifteenh.io/api/teamwork/member/payment/cancel";

    if (
      getParameterByName("type") &&
      getParameterByName("type") == "redirect"
    ) {
      document.querySelectorAll(".go_schedule_button")[0].style.display =
        "none";
      document
        .querySelectorAll(".button_wrapper_payment.before_cal")[0]
        .querySelectorAll("li")[1].style.display = "none";
    }

    if (getParameterByName("type") == "redirect") {
      url = `https://api.olapse.fifteenh.io/api/teamwork/member/payment/detail?id=${getParameterByName(
        "idx"
      )}&type=${getParameterByName("type")}`;
      cancelUrl = "https://api.olapse.fifteenh.io/api/member/payment/cancel";
    } else {
      url = `https://api.olapse.fifteenh.io/api/teamwork/member/payment/detail?id=${getParameterByName(
        "idx"
      )}`;
      cancelUrl = "https://api.olapse.fifteenh.io/api/teamwork/member/payment/cancel";
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.data.schedule.is_cancel == 1) {
          document.querySelectorAll(".before_cal").forEach((item, idx) => {
            item.style.display = "none";
          });
          document
            .querySelectorAll(".after_cancel_cal")
            .forEach((item, idx) => {
              item.style.display = "none";
            });
          document.querySelectorAll(".isCancel")[0].style.display = "flex";
          // document.querySelectorAll(".payment_cancel")[0].parentNode.style.display = "none";
          document.querySelector("#cancel_price").textContent = data.data
            .schedule.price
            ? numberWithCommas(data.data.schedule.price) + "원"
            : "-";
          document.querySelector("#cancel_date").textContent = data.data
            .schedule.canceled_at
            ? data.data.schedule.canceled_at.split(" ")[0]
            : "-";

          document.querySelectorAll(".payment_detailTitle")[0].textContent =
            "결제 취소 정보";
        } else {
          document.querySelectorAll(".payment_detailTitle")[0].textContent =
            "정산 정보";
          if (data?.data?.schedule?.calculate_status == 1) {
            //정산 후
            document.querySelectorAll(".before_cal").forEach((item, idx) => {
              item.style.display = "none";
              document.querySelector("#status").textContent = "정산완료";
            });
            document.querySelectorAll(".cancel_cal").forEach((item, idx) => {
              item.style.display = "none";
            });
            document.querySelector("#status_price").textContent = data.data
              .schedule.price
              ? numberWithCommas(data.data.schedule.price) + "원"
              : "-";
            document.querySelector("#status_date").textContent = data.data
              .schedule.calculated_at
              ? data.data.schedule.calculated_at.split(" ")[0]
              : "-";
          } else {
            //정산 전
            document.querySelectorAll(".after_cal").forEach((item, idx) => {
              item.style.display = "none";
              document.querySelector("#status").textContent = "미정산";
            });

            document.querySelectorAll(".cancel_cal").forEach((item, idx) => {
              item.style.display = "none";
            });
          }
        }

        document.querySelector("#title").textContent =
          getParameterByName("type") && getParameterByName("type") == "redirect"
            ? "-"
            : data.data.schedule.title;
        document.querySelector("#name").textContent =
          getParameterByName("type") && getParameterByName("type") == "redirect"
            ? "-"
            : data.data.schedule.name;
        document.querySelector("#info").textContent =
          data.data.schedule.card_num;
        document.querySelector("#month").textContent =
          data.data.schedule.card_quota == "00"
            ? "일시불"
            : data.data.schedule.card_quota + "개월";
        document.querySelector("#date").textContent = data.data.schedule
          .completed_at
          ? data.data.schedule.completed_at
          : "-";
        document.querySelector("#price").textContent =
          numberWithCommas(data.data.schedule.price) + "원";
      });
    document
      .querySelectorAll(".payment_cancel")[0]
      .addEventListener("click", function () {
        createPopup({
          type: "custom_select_2",
          title: "결제 취소",
          content: "결제를 취소하시겠습니까?",
          button_text_1: "취소",
          button_text_2: "확인",
          firstButtonClickFunction: () => {
            setTimeout(() => {
              exitPaymentExcute();
            }, 500);
          },
          secondButtonClickFunction: () => {},
        });
      });

    //결제 취소
    function exitPaymentExcute() {
      let formdata = new FormData();
      if (getParameterByName("type") == "redirect") {
        formdata.append("id", getParameterByName("idx"));
      } else {
        formdata.append("schedule_id", getParameterByName("idx"));
      }
      fetch(cancelUrl, {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.success == true) {
            createPopup({
              type: "custom",
              title: "결제 취소",
              content: "결제가 취소되었습니다.",
              custom: () => {
                navigate(-1);
              },
            });
          } else {
            createPopup({
              type: "reTry",
              title: "취소 실패",
              content: data.alert,
            });
          }
        });
    }
  }, []);

  const detailPopup = () => {
    _animateRightModule(true, document.querySelectorAll(".d_4")[0]);
    updateScheduleData(getParameterByName("idx"));
  };

  return (
    <>
      <div className="pc_right_area">
        <div className="pc_right_area_top">
          <div className="left">
            <img
              onClick={() => navigate(-1)}
              src="/assets/images/icon_back.svg"
              alt=""
            />
          </div>
          <h1 className="right_area_title">결제 상세</h1>
        </div>
        <div className="data_wrapper">
          <div className="payment_detail_wrapper">
            <h1>결제 정보</h1>
            <ul>
              <li>
                <h1>결제 팀워크</h1>
                <span id="title" />
              </li>
              <li>
                <h1>결제 멤버</h1>
                <span id="name" />
              </li>
              <li>
                <h1>결제 수단</h1>
                <span id="info" />
              </li>
              <li>
                <h1>할부 개월</h1>
                <span id="month" />
              </li>
              <li>
                <h1>결제 금액</h1>
                <span id="price" />
              </li>
              <li>
                <h1>결제 완료 일시</h1>
                <span id="date" />
              </li>
              <li
                style={{
                  display: "none",
                }}
                className="isCancel"
              >
                <h1>결제 취소 여부</h1>
                <span>취소</span>
              </li>
            </ul>
          </div>
          <div style={{ border: 0 }} className="payment_detail_wrapper_2">
            <h1 className="payment_detailTitle">정산 정보</h1>
            <ul>
              <li className="after_cancel_cal">
                <h1>정산 상태</h1>
                <span id="status" />
              </li>
              <li className="after_cal after_cancel_cal">
                <h1>정산 금액</h1>
                <span id="status_price" />
              </li>
              <li className="after_cal after_cancel_cal">
                <h1>정산 일</h1>
                <span id="status_date" />
              </li>
              <li className="cancel_cal">
                <h1>결제 취소 금액</h1>
                <span id="cancel_price" />
              </li>
              <li className="cancel_cal">
                <h1>결제 취소 일</h1>
                <span id="cancel_date" />
              </li>
            </ul>

            <ul className="button_wrapper_payment after_cal">
              <li>
                <button
                  onClick={() => detailPopup()}
                  className="go_schedule_button"
                >
                  해당 일정 보기
                </button>
              </li>
            </ul>
            <ul
              style={{ flexDirection: "row" }}
              className="button_wrapper_payment before_cal"
            >
              <li>
                <button className="payment_cancel">결제취소</button>
              </li>
              <li>
                <button
                  onClick={() => detailPopup()}
                  className="go_schedule_button"
                >
                  해당 일정 보기
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <TeamworkModule />
    </>
  );
};
export default PaymentDetail2;
