// 2022-02-24 인수인계
// 기능 : faq 상세 페이지입니다.

import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import ChatHeader from "./chat_header";
import { noData, getParameterByName } from "../shared/script";
const FaqDetail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      `https://api.olapse.fifteenh.io/api/qna/detail?id=${getParameterByName("idx")}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        //질문
        (document.querySelectorAll(
          "div.notice_detail_wrapper div.title_area h1"
        )[0].textContent = data.data.qna.type),
          (document.querySelectorAll(
            "div.notice_detail_wrapper div.title_area span"
          )[0].textContent = data.data.qna.created_at),
          (document.querySelectorAll(
            "div.notice_detail_wrapper div.notice_contents span"
          )[0].textContent = data.data.qna.contents);
        if (data.data.qna_images.length !== 0) {
          data.data.qna_images.forEach((item, idx) => {
            document
              .querySelectorAll(".notice_contents .faq_sub_title")[0]
              .insertAdjacentHTML(
                "afterend",
                `<img src="${item.image_url}" />`
              );
          });
        }

        //답변
        document.querySelectorAll("div.faq_response span")[0].textContent =
          data.data.qna.answer;
        if (data.data.qna_answer_images.length !== 0) {
          data.data.qna_answer_images.forEach((item, idx) => {
            document
              .querySelectorAll(".faq_response .faq_sub_title")[0]
              .insertAdjacentHTML(
                "afterend",
                `<img src="${item.image_url}" />`
              );
          });
        }
      });
  }, []);

  return (
    <>
      <div className="pc_right_area">
        <ChatHeader title="문의내역" />
        <div className="notice_detail_wrapper">
          <div className="title_area">
            <h1>문의내용</h1>
            <span>2021.08.08</span>
          </div>
          <div className="notice_contents">
            <h1 className="faq_sub_title">문의내용</h1>
            <span />
          </div>
          <div className="faq_response">
            <h1 className="faq_sub_title">답변내용</h1>
            <span />
          </div>
        </div>
      </div>
    </>
  );
};
export default FaqDetail;
